export const defaultFirebase = {
  teamName: "timcoo",
  firebaseConfig: {
    apiKey: "AIzaSyCc7x7I8ZqMkwTEbkgHTr-o8VkgLUklsrQ",
    authDomain: "timcoo-cf0dc.firebaseapp.com",
    databaseURL: "https://timcoo-cf0dc.firebaseio.com",
    projectId: "timcoo-cf0dc",
    storageBucket: "timcoo-cf0dc.appspot.com",
    messagingSenderId: "848141043911",
    appId: "1:848141043911:web:d426fb3da5910f808fd6e2",
    measurementId: "G-4RJGP64P2L",
  },
};

export const teamFirebase = [
  {
    teamName: "grupopardini",
    firebaseConfig: {
      appId: "1:848141043911:web:3795348f9e2b64718fd6e2",
      measurementId: "G-VXP574HLS2",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "ECOAR",
    firebaseConfig: {
      appId: "1:848141043911:web:d426fb3da5910f808fd6e2",
      measurementId: "G-4RJGP64P2L",
      gtagId: "G-3YJBHJ0NEM",
    },
  },
  {
    teamName: "CENTRO DE MEDICINA",
    firebaseConfig: {
      appId: "1:848141043911:web:056b4a53f9e656f78fd6e2",
      measurementId: "G-4TX0D8HQ2E",
      gtagId: "G-5RSQ1SGD2X",
    },
  },
  {
    teamName: "SOLUÇÃO",
    firebaseConfig: {
      appId: "1:848141043911:web:c6582605ab5b79488fd6e2",
      measurementId: "G-HH2TD6MP6Q",
      gtagId: "G-5G1KW9HTZ7",
    },
  },
  {
    teamName: "PROGENÉTICA",
    firebaseConfig: {
      appId: "1:848141043911:web:a9fe68df7ca7521f8fd6e2",
      measurementId: "G-178B0MW97V",
    },
  },
  {
    teamName: "DLE RJ",
    firebaseConfig: {
      appId: "1:848141043911:web:a382276b1dc03c618fd6e2",
      measurementId: "G-WWSX8NNM63",
    },
  },
  {
    teamName: "DLE SP",
    firebaseConfig: {
      appId: "1:848141043911:web:7f4669496096ea7a8fd6e2",
      measurementId: "G-CBK4E0QHQ5",
    },
  },
  {
    teamName: "TOXICOLOGIA MG",
    firebaseConfig: {
      appId: "1:848141043911:web:394ad52f329447828fd6e2",
      measurementId: "G-WBPL6698EX",
    },
  },
  {
    teamName: "TOXICOLOGIA SP",
    firebaseConfig: {
      appId: "1:848141043911:web:ea2fc52fd4f03b068fd6e2",
      measurementId: "G-24ZZYBTKWF",
    },
  },
  {
    teamName: "LABCLASS",
    firebaseConfig: {
      appId: "1:848141043911:web:1b44d632effd86f38fd6e2",
      measurementId: "G-85T4R9B3JV",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "DIAGNÓSTIKA SP",
    firebaseConfig: {
      appId: "1:848141043911:web:34f24d9acae515228fd6e2",
      measurementId: "G-VXP574HLS2",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "DIAGNÓSTIKA RJ",
    firebaseConfig: {
      appId: "1:848141043911:web:14c83acc834968ca8fd6e2",
      measurementId: "G-VXP574HLS2",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "PADRÃO",
    firebaseConfig: {
      appId: "1:848141043911:web:a0d11438962880f68fd6e2",
      measurementId: "G-T1R4WZ03RM",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "FERAs",
    firebaseConfig: {
      appId: "1:848141043911:web:38e7b491e122af5c8fd6e2",
      measurementId: "G-FFT2HKVMCT",
      gtagId: "G-N168BLH1KY",
    },
  },
  {
    teamName: "Hubsocial",
    firebaseConfig: {
      appId: "1:848141043911:web:ca0499d48ad80cec8fd6e2",
      measurementId: "G-YKTRSRF1FT",
      gtagId: "G-M6YPT20JXX",
    },
  },
  {
    teamName: "MRN",
    firebaseConfig: {
      appId: "1:848141043911:web:fde43891fb626d428fd6e2",
      measurementId: "G-PS1RC1VL51",
      gtagId: "G-K3K3Q7SSLK",
    },
  },
  {
    teamName: "Demo",
    firebaseConfig: {
      appId: "1:848141043911:web:8b323268bcb931b08fd6e2",
      measurementId: "G-2281JQHEG2",
      gtagId: "G-JJ2QXRLMYR",
    },
  },
  {
    teamName: "The Bakery Brasil",
    firebaseConfig: {
      appId: "1:848141043911:web:e747ec35ddd4a6a88fd6e2",
      measurementId: "G-BMZK1F5VYH",
      gtagId: "G-NLMF5Z2575",
    },
  },
  {
    teamName: "Safers",
    firebaseConfig: {
      appId: "1:848141043911:web:349a810c9bef8d208fd6e2",
      measurementId: "G-DDDZFL2Q1T",
      gtagId: "G-CSYGST2E3M",
    },
  },
  {
    teamName: "Noz",
    firebaseConfig: {
      appId: "1:848141043911:web:b03d9862c5e1e4718fd6e2",
      measurementId: "G-RVYPV1ZEM5",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "Ets",
    firebaseConfig: {
      appId: "1:848141043911:web:1fa391165b3e49c18fd6e2",
      measurementId: "G-0CM1EH3MCX",
      gtagId: "G-KHYPE0FVX8",
    },
  },
  {
    teamName: "Time Cibra",
    firebaseConfig: {
      appId: "1:848141043911:web:be3576043efa506e8fd6e2",
      measurementId: "G-GN9Z233CVC",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "sample",
    firebaseConfig: {
      apiKey: "AIzaSyCxu0cTFyiYs1AxVh1V5o-tolonlOcat5Q",
      authDomain: "sample-homolog.firebaseapp.com",
      databaseURL: "https://sample-homolog.firebaseio.com",
      projectId: "sample-homolog",
      storageBucket: "sample-homolog.appspot.com",
      messagingSenderId: "516245843351",
      appId: "1:516245843351:web:921a8fb505f329587268d2",
      measurementId: "G-52SWDY371V",
      gtagId: "G-YWL6Z9S94N",
    },
  },
  {
    teamName: "Ioasys",
    firebaseConfig: {
      apiKey: "AIzaSyD3kBS6hKoKtdEYD1YFEp5QnYLZj5AJth4",
      authDomain: "ioasys-team.firebaseapp.com",
      databaseURL: "https://ioasys-team.firebaseio.com",
      projectId: "ioasys-team",
      storageBucket: "ioasys-team.appspot.com",
      messagingSenderId: "983551189676",
      appId: "1:983551189676:web:ff4ccc1eb7d63cc3fd3509",
      measurementId: "G-FP04Q6YPG3",
      gtagId: "G-V25XQ8NDQN",
    },
  },
  {
    teamName: "Pintou Parceria Suvinil",
    firebaseConfig: {
      apiKey: "AIzaSyBwqBhJ3cfb1scrczWcIsjvxLrpJV6wBOc",
      authDomain: "pintouparceriasuvinil.firebaseapp.com",
      databaseURL: "https://pintouparceriasuvinil-default-rtdb.firebaseio.com",
      projectId: "pintouparceriasuvinil",
      storageBucket: "pintouparceriasuvinil.appspot.com",
      messagingSenderId: "865861034682",
      appId: "1:865861034682:web:39f9c26826f4538c8c9883",
      measurementId: "G-Q1G4E7W02F",
      gtagId: "G-MHH31P7WZL",
    },
  },
  {
    teamName: "Youseed",
    firebaseConfig: {
      apiKey: "AIzaSyBJ8e9XPyseaFV-I9uTExyDOi21sX-E-tM",
      authDomain: "banco-semear.firebaseapp.com",
      databaseURL: "https://banco-semear.firebaseio.com",
      projectId: "banco-semear",
      storageBucket: "banco-semear.appspot.com",
      messagingSenderId: "890508377656",
      appId: "1:890508377656:web:be6f3e39c9166a51dc30cd",
      measurementId: "G-02QV13RWHT",
      gtagId: "G-NGWX3QYK0N",
    },
  },
  {
    teamName: "Big",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:8a3e38018591cdc4a581f9",
      measurementId: "G-FKE4T5WXX3",
      gtagId: "G-YHMFWDKZ0D",
    },
  },
  {
    teamName: "Sou Smarttbotter",
    firebaseConfig: {
      apiKey: "AIzaSyCjeKy1le_qCGHh2XhnOBPhYZj-XveTF-w",
      authDomain: "smarttbot-noz.firebaseapp.com",
      projectId: "smarttbot-noz",
      storageBucket: "smarttbot-noz.appspot.com",
      messagingSenderId: "635892766612",
      appId: "1:635892766612:web:1c058860db46794217e181",
      measurementId: "G-V0K655P5R3",
      gtagId: "G-NGFZ6G4FV4",
    },
  },
  {
    teamName: "PetroRio",
    firebaseConfig: {
      apiKey: "AIzaSyAaQha_bI4q8f_rNOEwFkpXkTGynoSLEsg",
      authDomain: "petrorio-2b9d4.firebaseapp.com",
      projectId: "petrorio-2b9d4",
      storageBucket: "petrorio-2b9d4.appspot.com",
      messagingSenderId: "365216947240",
      appId: "1:365216947240:web:e3cdd18adeb41f8c8b291d",
      measurementId: "G-CR1SH9M204",
      gtagId: "G-QH6QT66NHV",
    },
  },
  {
    teamName: "Astro",
    firebaseConfig: {
      apiKey: "AIzaSyBef0IDx4okbUWofkKtLqIwAAbNhNzU1TQ",
      authDomain: "astro-bb24d.firebaseapp.com",
      projectId: "astro-bb24d",
      storageBucket: "astro-bb24d.appspot.com",
      messagingSenderId: "436355814307",
      appId: "1:436355814307:web:c0cb5ea66957e841c68016",
      measurementId: "G-JMQBPXP9WT",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "LPA",
    firebaseConfig: {
      appId: "1:848141043911:web:b3bbce899b2db66d8fd6e2",
      measurementId: "G-GJ810ST4TD",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "APC",
    firebaseConfig: {
      appId: "1:848141043911:web:9d076dd9c6150b038fd6e2",
      measurementId: "G-E04MD27NZH",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "ODIVÂNIA",
    firebaseConfig: {
      appId: "1:848141043911:web:9d076dd9c6150b038fd6e2",
      measurementId: "G-LP5XBPY6VR",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "IACS",
    firebaseConfig: {
      appId: "1:516245843351:web:072f24d5a0eecb377268d2",
      measurementId: "G-8RRXHK7FN8",
      gtagId: "G-YWL6Z9S94N",
    },
  },
  {
    teamName: "CSV",
    firebaseConfig: {
      appId: "1:516245843351:web:5d9da011a068d8277268d2",
      measurementId: "G-N0L5LW03ZR",
      gtagId: "G-YWL6Z9S94N",
    },
  },
  {
    teamName: "HP SP",
    firebaseConfig: {
      appId: "1:516245843351:web:efc0302ce5031c587268d2",
      measurementId: "G-20XGBHPT1F",
      gtagId: "G-YWL6Z9S94N",
    },
  },
  {
    teamName: "HP MG",
    firebaseConfig: {
      appId: "1:516245843351:web:852e88e5ff5272457268d2",
      measurementId: "G-PB4GXJG51W",
      gtagId: "G-YWL6Z9S94N",
    },
  },
  {
    teamName: "METHODOS",
    firebaseConfig: {
      apiKey: "AIzaSyAVLDNZESXk_-aAZs05qwoCm0Q0APkcKCg",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:a912aa39819c7585a581f9",
      measurementId: "G-74192TQ0YY",
      gtagId: "G-YWL6Z9S94N",
    },
  },
];
