import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  publicationsRequest: ["payload"],
  favoritePublicationsRequest: ["payload"],
  publicationsSuccess: ["payload", "isLoading"],
  publicationsFail: ["isLoading", "error"],

  quizPublicationsRequest: ["payload"],
  quizPublicationsSuccess: ["payload", "isLoading"],
  quizPublicationsFail: ["isLoading", "error"],

  surveyPublicationsRequest: ["payload"],
  surveyPublicationsSuccess: ["payload", "isLoading"],
  surveyPublicationsFail: ["isLoading", "error"],

  postFavoritePublicationRequest: ["payload"],
  postFavoritePublicationSuccess: ["payload", "isLoading"],
  postFavoritePublicationFail: ["isLoading", "error"],

  deleteFavoritePublicationRequest: ["payload"],
  deleteFavoritePublicationSuccess: ["payload", "isLoading"],
  deleteFavoritePublicationFail: ["isLoading", "error"],

  clean: [],
});

const INITIAL_STATE = {
  publications: [],
  favoritePublications: [],
  searchingFor: "",
  isLoading: true,
  isLoadingFavorites: false,
  hasMore: true,
  hasMoreFavorites: false,
  page: 1,
  pageFavorites: 1,
  error: [],
};

const publicationsRequest = (state) => ({
  ...state,
});
const favoritePublicationsRequest = (state) => ({
  ...state,
  isLoadingFavorites: true,
});
const publicationsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
  isLoadingFavorites: false,
});
const publicationsFail = (state, error) => ({
  ...state,
  isLoading: false,
  isLoadingFavorites: false,
  ...error,
});

const quizPublicationsRequest = (state) => ({ ...state });
const quizPublicationsSuccess = (state, payload) => {
  const { idPublication, answer } = payload.payload;
  const changeAnswer = (post) => ({
    ...post,
    allVotesAmount: post.allVotesAmount + 1,
    data: post.data.map((option) =>
      answer.find((res) => res === option.id)
        ? { ...option, voted: true, votes_amount: option.votes_amount + 1 }
        : option,
    ),
  });

  const posts = state.publications;
  const changedPosts = posts.map((post) =>
    post.id === idPublication ? changeAnswer(post) : post,
  );

  return {
    ...state,
    publications: changedPosts,
    isLoading: false,
  };
};
const quizPublicationsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const surveyPublicationsRequest = (state) => ({ ...state });
const surveyPublicationsSuccess = (state, { payload }) => {
  const { idPublication, idOption } = payload;
  const changeAnswer = (post) => ({
    ...post,
    allVotesAmount: post.allVotesAmount + 1,
    data: post.data.map((option) =>
      option.id === idOption
        ? { ...option, voted: true, votes_amount: option.votes_amount + 1 }
        : option,
    ),
  });

  const posts = state.publications;
  const changedPosts = posts.map((post) =>
    post.id === idPublication ? changeAnswer(post) : post,
  );

  return {
    ...state,
    publications: changedPosts,
    isLoading: false,
  };
};
const surveyPublicationsFail = (state, { payload }) => ({
  ...state,
  isLoading: false,
  ...payload,
});

const postFavoritePublicationRequest = (state) => ({
  ...state,
});
const postFavoritePublicationSuccess = (state, { payload }) => {
  const publications = state.publications.map((publication) => {
    if (publication.id === payload.id) {
      return { ...publication, favorite: true };
    }
    return publication;
  });
  return {
    ...state,
    publications,
  };
};
const postFavoritePublicationFail = (state, error) => ({
  ...state,
  ...error,
});

const deleteFavoritePublicationRequest = (state) => ({
  ...state,
});
const deleteFavoritePublicationSuccess = (state, { payload }) => {
  const publications = state.publications.map((publication) => {
    if (publication.id === payload.id) {
      return { ...publication, favorite: false };
    }
    return publication;
  });
  return {
    ...state,
    publications,
  };
};
const deleteFavoritePublicationFail = (state, error) => ({
  ...state,
  ...error,
});

const clean = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [Types.PUBLICATIONS_REQUEST]: publicationsRequest,
  [Types.FAVORITE_PUBLICATIONS_REQUEST]: favoritePublicationsRequest,
  [Types.PUBLICATIONS_SUCCESS]: publicationsSuccess,
  [Types.PUBLICATIONS_FAIL]: publicationsFail,

  [Types.QUIZ_PUBLICATIONS_REQUEST]: quizPublicationsRequest,
  [Types.QUIZ_PUBLICATIONS_SUCCESS]: quizPublicationsSuccess,
  [Types.QUIZ_PUBLICATIONS_FAIL]: quizPublicationsFail,

  [Types.SURVEY_PUBLICATIONS_REQUEST]: surveyPublicationsRequest,
  [Types.SURVEY_PUBLICATIONS_SUCCESS]: surveyPublicationsSuccess,
  [Types.SURVEY_PUBLICATIONS_FAIL]: surveyPublicationsFail,

  [Types.POST_FAVORITE_PUBLICATION_REQUEST]: postFavoritePublicationRequest,
  [Types.POST_FAVORITE_PUBLICATION_SUCCESS]: postFavoritePublicationSuccess,
  [Types.POST_FAVORITE_PUBLICATION_FAIL]: postFavoritePublicationFail,

  [Types.DELETE_FAVORITE_PUBLICATION_REQUEST]: deleteFavoritePublicationRequest,
  [Types.DELETE_FAVORITE_PUBLICATION_SUCCESS]: deleteFavoritePublicationSuccess,
  [Types.DELETE_FAVORITE_PUBLICATION_FAIL]: deleteFavoritePublicationFail,

  [Types.CLEAN]: clean,
});
