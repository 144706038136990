import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getProducts: [""],
  getProductsSuccess: ["payload", "isLoading"],
  getProductsFail: ["isLoading", "error"],

  getDetailsProduct: ["id"],
  getDetailsProductSuccess: ["payload", "isLoading"],
  getDetailsProductFail: ["isLoading", "error"],

  buyProduct: ["payload"],
  buyProductSuccess: ["payload", "isLoading"],
  buyProductFail: ["isLoading", "error"],

  getRescue: [""],
  getRescueSuccess: ["payload", "isLoading"],
  getRescueFail: ["isLoading", "error"],
});
const INITIAL_STATE = {
  products: [],
  product: {},
  rescue: {},
  successPurchase: false,
  isLoading: true,
  hasMore: true,
  page: 1,
};

const getProducts = (state) => ({
  ...state,
  isLoading: true,
});

const getProductsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getProductsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getDetailsProduct = () => ({ ...INITIAL_STATE });
const getDetailsProductSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getDetailsProductFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const buyProduct = (state) => ({ ...state, isLoading: true });
const buyProductSuccess = (state) => ({
  ...state,
  successPurchase: true,
  isLoading: false,
});
const buyProductFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getRescue = (state) => ({ ...state });
const getRescueSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getRescueFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCTS]: getProducts,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [Types.GET_PRODUCTS_FAIL]: getProductsFail,

  [Types.GET_DETAILS_PRODUCT]: getDetailsProduct,
  [Types.GET_DETAILS_PRODUCT_SUCCESS]: getDetailsProductSuccess,
  [Types.GET_DETAILS_PRODUCT_FAIL]: getDetailsProductFail,

  [Types.BUY_PRODUCT]: buyProduct,
  [Types.BUY_PRODUCT_SUCCESS]: buyProductSuccess,
  [Types.BUY_PRODUCT_FAIL]: buyProductFail,

  [Types.GET_RESCUE]: getRescue,
  [Types.GET_RESCUE_SUCCESS]: getRescueSuccess,
  [Types.GET_RESCUE_FAIL]: getRescueFail,
});
