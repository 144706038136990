import { all } from "redux-saga/effects";
import * as AuthSaga from "./authSaga";
import * as MuralSaga from "./muralSaga";
import * as NotificationsSaga from "./notificationsSaga";
import * as DetailsPublication from "./detailsPublicationSaga";
import * as CalendarEvents from "./calendarSaga";
import * as FilesSaga from "./filesSaga";
import * as ModalAlertSaga from "./modalAlertSaga";
import * as UserSaga from "./userSaga";
import * as StoreSaga from "./storeSaga";
import * as AcademySaga from "./academySaga";
import * as TeamSaga from "./teamSaga";
import * as BirthdaysSaga from "./birthdaysSaga";
import * as ScoresHistorySaga from "./scoresHistorySaga";
import * as AppsSaga from "./appsSaga";
import * as GallerySaga from "./gallerySaga";
import * as ToolsExternalLinksSaga from "./toolsExternalLinksSaga";
import * as GroupPardiniSaga from "./groupPardiniSaga";
import * as HoleriteSaga from "./holeriteSaga";
import * as GuestAuthSaga from "./guestAuthSaga";
import * as PostComments from "./postCommentsSaga";

function* Sagas() {
  yield all([
    AuthSaga.watcherSaga(),
    MuralSaga.watcherSaga(),
    ModalAlertSaga.watcherSaga(),
    DetailsPublication.watcherSaga(),
    NotificationsSaga.watcherSaga(),
    CalendarEvents.watcherSaga(),
    UserSaga.watcherSaga(),
    FilesSaga.watcherSaga(),
    StoreSaga.watcherSaga(),
    AcademySaga.watcherSaga(),
    TeamSaga.watcherSaga(),
    BirthdaysSaga.watcherSaga(),
    ScoresHistorySaga.watcherSaga(),
    AppsSaga.watcherSaga(),
    GallerySaga.watcherSaga(),
    ToolsExternalLinksSaga.watcherSaga(),
    GroupPardiniSaga.watcherSaga(),
    HoleriteSaga.watcherSaga(),
    GuestAuthSaga.watcherSaga(),
    PostComments.watcherSaga(),
  ]);
}

export default Sagas;
