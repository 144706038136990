import React from "react";
import PropTypes from "prop-types";
import urls from "utils/constants/urls";
import { history, convertToSlug } from "helpers/sharedHelpers";

import { ReactComponent as GroupPardiniIcon } from "assets/img/icons/groupPardini.svg";

import * as S from "./MenuComponentStyle";

const MenuComponent = ({ listMenu, type }) => (
  <S.GeneralContainer>
    {listMenu.map(({ id, title, kind, image }) => (
      <S.ButtonContainer>
        <S.ButtonLink
          onClick={() =>
            history.push(
              type !== "aboutUs"
                ? `${
                    kind === "menu"
                      ? urls.ROUTES.OUR_COMPANIES_PARDINI
                      : urls.ROUTES.SUB_MENU_PARDINI
                  }/${convertToSlug(title)}/${id}`
                : `${urls.ROUTES.SUB_ABOUT_US}/${id}`,
            )
          }
        >
          {image?.url ? (
            <img src={image?.url} alt={title} />
          ) : (
            <GroupPardiniIcon />
          )}
        </S.ButtonLink>
        <S.FolderName>{title}</S.FolderName>
      </S.ButtonContainer>
    ))}
  </S.GeneralContainer>
);

MenuComponent.defaultProps = {
  listMenu: [],
  type: "Companies",
};

MenuComponent.propTypes = {
  type: PropTypes.string,
  listMenu: PropTypes.arrayOf(PropTypes.object),
};

export default MenuComponent;
