import React, { useRef, useEffect } from "react";
import propType from "prop-types";

// eslint-disable-next-line react/prop-types
const Video = ({ url, ...rest }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [url]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={videoRef} {...rest}>
      <source src={url} type="video/mp4" />
    </video>
  );
};

Video.defaultProps = {
  url: propType.string,
};

export { Video };
