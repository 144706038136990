import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as ScoreHistoryActions } from "store/ducks/scoresHistory";
import { ReactComponent as UserWeekCoins } from "assets/newUserWeekCoins.svg";

import { Score } from "containers/ScoreHistory/ScoreHistoryStyle";
import { style } from "style";
import { MsgDoesNotExist, Overflow } from "../SidebarStyle";
import { ContainerWeekScore } from "./WeekScoreStyle";

const WeekScore = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { scoreUser } = useSelector((state) => state.scoreHistory);
  const weekScore = scoreUser.filter(
    (score) => score.kind === "week_interaction",
  );

  const [overflowHeight, setOverflowHeight] = useState();
  const dispatchScoreHistoryActions = useCallback(
    () => dispatch(ScoreHistoryActions.getScoresHistoryUser()),
    [dispatch],
  );

  useEffect(() => {
    setOverflowHeight(document.body.getBoundingClientRect().height / 3 - 90);
  }, []);
  useEffect(() => {
    dispatchScoreHistoryActions();
  }, [dispatchScoreHistoryActions]);

  return (
    <Flex flex={2} flexDirection="column" width="100%">
      <PageTitle
        title={intl.messages["scoreHistory.weeklyScore"]}
        fontSize={3}
      />
      <Overflow height={`${overflowHeight}px`}>
        <Flex p={2} alignItems="center">
          <ContainerWeekScore>
            <UserWeekCoins />
            <MsgDoesNotExist>
              {intl.messages["scoreHistory.inTheLastWeekYouReceived"]}
              <br />
              <Score variant={["coinsWeekSidebar"]}>
                {weekScore[0]?.score_sum} {style.names.nameScore}
              </Score>
            </MsgDoesNotExist>
          </ContainerWeekScore>
        </Flex>
      </Overflow>
    </Flex>
  );
};
export default WeekScore;
