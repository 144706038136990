export const professionalData = {
  workRegime: [
    {
      id: 0,
      text: "Autônomo sem PJ",
      value: "autonomous_non_legal_person",
    },
    {
      id: 1,
      text: "Autônomo como PJ",
      value: "autonomous_legal_person",
    },
    {
      id: 2,
      text: "CLT",
      value: "clt",
    },
  ],
};
