import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { TOOLS_EXTERNAL_LINKS } from "utils/constants/endpoints";
import { Types } from "../ducks/toolsExternalLinks";

function* getToolsExternalLinks() {
  try {
    const toolsExternalLinksAll = yield requestAPI({
      verb: GET,
      endPoint: TOOLS_EXTERNAL_LINKS,
    });
    yield put({
      toolsExternalLinksAll,
      type: Types.GET_TOOLS_EXTERNAL_LINKS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TOOLS_EXTERNAL_LINKS_FAIL,
      ...error.response.data,
      isLoadingTools: false,
    });
  }
}

function* getToolsExternalLinksFolder({ id }) {
  try {
    const galleryDetails = yield requestAPI({
      verb: GET,
      endPoint: TOOLS_EXTERNAL_LINKS,
      id,
    });
    yield put({
      galleryDetails,
      type: Types.GET_TOOLS_EXTERNAL_LINKS_FOLDER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TOOLS_EXTERNAL_LINKS_FOLDER_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_TOOLS_EXTERNAL_LINKS, getToolsExternalLinks);
  yield takeLatest(
    Types.GET_TOOLS_EXTERNAL_LINKS_FOLDER,
    getToolsExternalLinksFolder,
  );
}
