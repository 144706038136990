/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { v1 as uuidv1 } from "uuid";

import * as S from "./TeamStyle";

const SkeletonTeam = () => {
  const skeletonRepeat = 8;

  return (
    <S.SkeletonContainer
      p={4}
      height="100%"
      flexDirection="column"
      spaceChildren={3}
      borderRight="1px solid #ccd2e0"
    >
      {[...Array(skeletonRepeat)].map((_val) => (
        <Skeleton key={uuidv1()} duration={0.8} width="100%" height={68} />
      ))}
    </S.SkeletonContainer>
  );
};

export const SkeletonOneSector = () => {
  return (
    <Skeleton key={uuidv1()} duration={0.8} width="100%" height={68} />
  );
};
export const SkeletonUser = () => {
  return (
    <S.SkeletonUserContainer>
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
    </S.SkeletonUserContainer>
  );
};

export default SkeletonTeam;
