/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { style } from "style";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import {
  Input,
  InputMask,
  Textarea,
  Select,
  SwitchProfilePerfil,
} from "components/core/Form/Form";
import Button from "components/core/Button/Button";
import { history } from "helpers/sharedHelpers";
import moment from "moment";
import "moment/locale/pt-br";

import {
  ImageName,
  ButtonImage,
  ProfileImage,
  LabelButtonImage,
  LabelButtonRemove,
  ProfileHeader,
  ProfileForm,
  BoxProfile,
} from "./EditProfileStyle";

const EditProfile = ({ updateDataUser }) => {
  const { isLoadingUpdate, user, personalQuestions: questions } = useSelector(
    (state) => state.user,
  );
  const intl = useIntl();
  const [imgPreview, setImgPreview] = useState("");
  const [fileName, setFileName] = useState("");
  const [editPermission, setEditPermission] = useState({
    name: false,
    email: false,
    phone: false,
  });

  const idUser = localStorage.getItem("idUser");

  const listSexGender = [
    {
      text: "Mulher cis",
      id: 0,
      value: "cis_woman",
    },
    {
      text: "Mulher trans",
      id: 1,
      value: "trans_woman",
    },
    {
      text: "Homem cis",
      id: 2,
      value: "cis_man",
    },
    {
      text: "Homem trans",
      id: 3,
      value: "trans_man",
    },
    {
      text: "Não binário",
      id: 4,
      value: "not_binary",
    },
    {
      text: "Prefere não se identificar",
      id: 5,
      value: "prefers_not_to_identify",
    },
    {
      text: "Outro",
      id: 6,
      value: "other",
    },
  ];

  const updateSexGender = (genderUser) => {
    const Genders = {
      "Mulher cis": "cis_woman",
      "Mulher trans": "trans_woman",
      "Homem cis": "cis_man",
      "Homem trans": "trans_man",
      "Não binário": "not_binary",
      "Prefere não se identificar": "prefers_not_to_identify",
      default: "other",
    };

    return Genders[genderUser] || Genders.default;
  };

  const [form, setForm] = useState({});

  useEffect(() => {
    if (Object.keys(user).length) {
      setForm({
        image: user.image.url,
        name: user.name,
        email: user.email,
        sex: updateSexGender(user.sex),
        custom_gender: updateSexGender(user.sex) === "other" ? user.sex : "",
        show_gender: user.show_gender,
        datebirthday: moment(user.datebirthday).utc().format("DD-MM-YYYY"),
        bio: user.bio || "",
        interests: user.interests || "",
        skills: user.skills || "",
        contacts: [...user.contacts],
        show_datebirthday: user.show_datebirthday,
        show_email: user.show_email,
        show_phone: user.show_phone,
      });
    }
  }, [user]);

  const previewImg = (image) => {
    setFileName(image.target.files[0].name);
    setImgPreview(URL.createObjectURL(image.target.files[0]));

    setForm({ ...form, image: image.target.files[0] });
  };
  useEffect(() => {
    setEditPermission({
      name: form.name !== "",
      email: form.email !== "",
    });
  }, [form.name, form.email]);

  const changeContacts = (kind, newValue) => {
    const contact = form.contacts.find((contact) => contact.kind === kind);

    if (contact === undefined) {
      setForm({
        ...form,
        contacts: [
          ...form.contacts,
          {
            kind,
            contact: newValue,
          },
        ],
      });
    } else {
      setForm({
        ...form,
        contacts: form.contacts?.map((contact) =>
          contact.kind === kind
            ? {
                ...contact,
                contact: newValue,
              }
            : contact,
        ),
      });
    }
  };

  const RemoveProfileImg = () => {
    setFileName("");
    setImgPreview("");
    setForm({ ...form, image: "" });
  };

  const updateUser = (e) => {
    e.preventDefault();

    updateDataUser(form);
    history.push(`/perfil/${idUser}`);
  };

  return (
    <BoxProfile>
      <ProfileForm onSubmit={(e) => updateUser(e)}>
        <PageTitle title={intl.messages["profile.textPage.myProfile"]}>
          <Flex alignItems="center" spaceChildren={3}>
            <ProfileHeader>
              <Button
                name={intl.messages["general.buttons.back"]}
                variant={["transparent"]}
                action={() => history.push(`/perfil/${idUser}`)}
              />

              {Object.values(editPermission).indexOf(false) === -1 && (
                <Button
                  type="submit"
                  name={intl.messages["general.buttons.save"]}
                  isLoading={isLoadingUpdate}
                  disabled={isLoadingUpdate}
                />
              )}
            </ProfileHeader>
          </Flex>
        </PageTitle>

        <Flex
          p={3}
          flexDirection="column"
          spaceChildren={3}
          maxWidth="500px"
          height="100%"
        >
          <Flex mb={5} spaceChildren={3}>
            <Flex flexDirection="column">
              <ProfileImage image={imgPreview || form.image}>
                <ButtonImage
                  id="upload"
                  type="file"
                  accept="image/*"
                  onChange={(e) => previewImg(e)}
                />
                <LabelButtonImage htmlFor="upload">
                  {form.image || imgPreview
                    ? intl.messages["general.buttons.change"]
                    : intl.messages["general.buttons.add"]}
                </LabelButtonImage>
              </ProfileImage>
              {(form.image || imgPreview) && (
                <LabelButtonRemove onClick={RemoveProfileImg}>
                  {intl.messages["general.buttons.remove"]}
                </LabelButtonRemove>
              )}
            </Flex>
            {imgPreview && (
              <ImageName>
                Foto de avatar
                <h3 image={!!imgPreview} id="nameImg">
                  {imgPreview
                    ? fileName
                    : intl.messages["profile.textPage.addImage"]}
                </h3>
              </ImageName>
            )}
          </Flex>
          <Input
            name="name"
            value={form.name}
            error={
              form.name === "" && intl.messages["general.buttons.inputRequired"]
            }
            placeholder={`${intl.messages["profile.textPage.typeYourName"]}`}
            label={`${intl.messages["profile.name"]}*`}
            onChange={(e) => {
              setEditPermission({
                ...editPermission,
                name: e.target.value !== "",
              });
              setForm({ ...form, name: e.target.value });
            }}
          />
          {questions[0] !== undefined && (
            <Textarea
              rows={6}
              name="bio"
              value={form.bio}
              placeholder={questions[0].placeholder === null ? "": `${questions[0].placeholder}`}
              label={`${questions[0].title}`}
              onChange={(e) => setForm({ ...form, bio: e.target.value })}
              isPersonalQuestion
            />
          )}

          <InputMask
            mask="99/99/9999"
            maskChar={null}
            type="text"
            name="datebirthday"
            value={form.datebirthday}
            placeholder={`${intl.messages["profile.textPage.typeYourBirthday"]}`}
            label={intl.messages["profile.datebirthday"]}
            onChange={(e) => {
              setForm({ ...form, datebirthday: e.target.value });
            }}
          />
          <SwitchProfilePerfil
            title="Exibir data de aniversário:"
            active={form.show_datebirthday}
            onChange={() =>
              setForm({ ...form, show_datebirthday: !form.show_datebirthday })
            }
          />
          <Input
            name="emailContact"
            value={
              form.contacts?.find((contact) => contact.kind === "email")
                ?.contact || ""
            }
            placeholder={`${intl.messages["profile.textPage.typeYourEmail"]}`}
            label={
              style.names.nameApp === "Pintou Parceria Suvinil"
                ? "E-MAIL"
                : "E-MAIL"
            }
            onChange={(e) => changeContacts("email", e.target.value)}
            defaultValue="@"
          />
          <SwitchProfilePerfil
            title="Exibir e-mail:"
            active={form.show_email}
            onChange={() => setForm({ ...form, show_email: !form.show_email })}
          />
          {style.variables.gamificationEnabled && (
            <>
              <Select
                value={form.sex}
                options={listSexGender}
                label={`${intl.messages["profile.gender"]}`}
                onChange={(e) => setForm({ ...form, sex: e.target.value })}
              />
              {form.sex === "other" && (
                <Input
                  name="name"
                  value={form.custom_gender}
                  label={`${intl.messages["profile.otherGender"]}`}
                  placeholder={`${intl.messages["profile.textPage.typeYourGender"]}`}
                  onChange={(e) =>
                    setForm({ ...form, custom_gender: e.target.value })
                  }
                />
              )}
              <SwitchProfilePerfil
                title="Exibir gênero:"
                active={form.show_gender}
                onChange={() =>
                  setForm({ ...form, show_gender: !form.show_gender })
                }
              />
            </>
          )}
          <InputMask
            mask="(99) 9 9999-9999"
            maskChar={null}
            type="text"
            name="phone"
            value={
              form.contacts?.find((contact) => contact.kind === "phone")
                ?.contact || ""
            }
            placeholder={`${intl.messages["profile.textPage.typePhone"]}`}
            label={`${intl.messages["profile.phone"]}`}
            onChange={(e) => changeContacts("phone", e.target.value)}
          />
          <SwitchProfilePerfil
            title="Exibir telefone:"
            active={form.show_phone}
            onChange={() => setForm({ ...form, show_phone: !form.show_phone })}
          />
          {style.variables.child_profiles.length > 0 && (
            <InputMask
              mask="(99) 9999-9999"
              maskChar={null}
              type="text"
              name="extension_line"
              value={
                form.contacts?.find(
                  (contact) => contact.kind === "extension_line",
                )?.contact || ""
              }
              placeholder={`${intl.messages["profile.textPage.typePhoneRamal"]}`}
              label={`${intl.messages["profile.phoneRamal"]}`}
              onChange={(e) => changeContacts("extension_line", e.target.value)}
            />
          )}
          {questions[1] !== undefined && (
            <Textarea
              name="interests"
              value={form.interests}
              placeholder={questions[1].placeholder === null ? "":`${questions[1].placeholder}`}
              label={`${questions[1].title}`}
              onChange={(e) => setForm({ ...form, interests: e.target.value })}
              isPersonalQuestion
            />
          )}
          {questions[2] !== undefined && (
            <Textarea
              name="skills"
              value={form.skills}
              placeholder={questions[2].placeholder === null ? "":`${questions[2].placeholder}`}
              label={`${questions[2].title}`}
              onChange={(e) => setForm({ ...form, skills: e.target.value })}
              isPersonalQuestion
            />
          )}
          {style.variables.gamificationEnabled && (
            <>
              <Input
                iconSrc="facebook"
                name="facebook"
                prefix="facebook.com/"
                value={
                  form.contacts?.find((contact) => contact.kind === "facebook")
                    ?.contact || ""
                }
                placeholder={`${intl.messages["profile.textPage.typeFacebook"]}`}
                label={intl.messages["profile.facebook"]}
                onChange={(e) => changeContacts("facebook", e.target.value)}
              />
              <Input
                iconSrc="instagram"
                name="instagram"
                prefix="@"
                value={
                  form.contacts?.find((contact) => contact.kind === "instagram")
                    ?.contact || ""
                }
                placeholder={`${intl.messages["profile.textPage.typeInstagram"]}`}
                label={intl.messages["profile.instagram"]}
                onChange={(e) => changeContacts("instagram", e.target.value)}
              />
              <Input
                iconSrc="twitter"
                name="twitter"
                prefix="@"
                value={
                  form.contacts?.find((contact) => contact.kind === "twitter")
                    ?.contact
                }
                placeholder={`${intl.messages["profile.textPage.typeTwitter"]}`}
                label={intl.messages["profile.twitter"]}
                onChange={(e) => changeContacts("twitter", e.target.value)}
              />
            </>
          )}
          <Input
            iconSrc="linkedin"
            name="linkedin"
            prefix="linkedin.com/in/"
            value={
              form.contacts?.find((contact) => contact.kind === "linkedin")
                ?.contact || ""
            }
            placeholder={`${intl.messages["profile.textPage.typeLinkedin"]}`}
            label={intl.messages["profile.linkedin"]}
            onChange={(e) => changeContacts("linkedin", e.target.value)}
          />
        </Flex>
      </ProfileForm>
    </BoxProfile>
  );
};

export default EditProfile;
