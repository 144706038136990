import React from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { takeLatest, put, call } from "redux-saga/effects";
import { GET, PUT } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { style } from "style";
import {
  GET_USER,
  GET_NOT_VIEWED_NOTIFICATIONS,
  GET_NOT_VIEWED_NOTIFICATIONS_SIZE,
  GET_TERMS,
  PUT_PASSWORD,
  GET_POLICIES_PRIVACY,
  GET_PERSONAL_QUESTIONS,
  GET_TOKEN_TUTORIAL,
} from "utils/constants/endpoints";
import urls from "utils/constants/urls";
import { Types } from "../ducks/user";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { styleTeamGrupoPardini } from "../../utils/constants/authenticatedSaml";
import { API_V2 } from "../../utils/API/API";


function* updatePassword({ payload }) {
  const idUser = localStorage.getItem("idUser");
  const body = { password: payload.new, current_password: payload.current };
  try {
    yield requestAPI({
      verb: PUT,
      endPoint: PUT_PASSWORD,
      id: idUser,
      data: body,
    });
    yield put({
      type: Types.UPDATE_PASSWORD_SUCCESS,
    });
    yield put({
      type: Types.GET_USER_REQUEST,
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Sua senha foi atualizada!",
        buttons: [
          {
            title: "OK",
            event: () => window.location.reload(true),
          },
        ],
      },
    });
  } catch (error) {
    const currentPassError = error.response.data.errors
      ? error.response.data.errors[0] ===
        "A validação falhou: Senha atual não é válido"
      : false;

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: "Não foi possivel alterar a senha, tente novamente",
      },
    });

    yield put({
      type: Types.UPDATE_PASSWORD_FAIL,
      currentPassError,
    });
  }
}

function* updateDataUser({ payload }) {
  const entries = Object.entries(payload);
  const idUser = localStorage.getItem("idUser");
  const formData = new FormData();
  const contacts_ids_to_delete = [];
  // eslint-disable-next-line
  entries.map((item) =>
    item[0] === "contacts" // eslint-disable-next-line
      ? item[1].map((item, i) => {
          if (item.id) {
            if (item.contact === "" && !style.variables.externalUse) {
              contacts_ids_to_delete.push(item.id);
              formData.append(`[contacts_ids_to_delete][${i}]`, item.id);
            } else {
              formData.append(`[contacts_attributes][${i}][id]`, item.id);
              formData.append(`[contacts_attributes][${i}][kind]`, item.kind);
              formData.append(
                `[contacts_attributes][${i}][contact]`,
                item.contact,
              );
            }
          } else {
            formData.append(`[contacts_attributes][${i}][kind]`, item.kind);
            formData.append(
              `[contacts_attributes][${i}][contact]`,
              item.contact,
            );
          }
        })
      : formData.append(`${item[0]}`, item[1]),
  );
  try {
    yield requestAPI({
      verb: PUT,
      endPoint: GET_USER,
      id: idUser,
      data: formData,
      contacts_ids_to_delete,
    });
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Seu perfil foi atualizado!",
        buttons: [
          {
            title: "OK",
            event: () => window.location.reload(true),
          },
        ],
      },
    });
    yield put({
      type: Types.UPDATE_DATA_USER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.UPDATE_DATA_USER_FAIL,
      ...error.response.data,
    });
  }
}

function* updateUserBrand({ payload }) {
  const idUser = localStorage.getItem("idUser");
  const formData = new FormData();
  formData.append(`current_brand_id`, payload);

  try {
    const user = yield requestAPI({
      verb: PUT,
      endPoint: GET_USER,
      id: idUser,
      data: formData,
      header: style.api.hostHeader,
    });
    yield call(styleTeamGrupoPardini, user.team);
    yield put({
      user,
      type: Types.UPDATE_USER_BRAND_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.UPDATE_USER_BRAND_FAIL,
      ...error.response.data,
    });
  }
}

function* getUserRequest() {
  try {
    const idUser = localStorage.getItem("idUser");
    const user = yield requestAPI({
      verb: GET,
      endPoint: GET_USER,
      id: idUser,
    });
    localStorage.setItem("roles", user.roles[0].name);
    yield put({
      type: Types.GET_NOT_VIEWD_NOTIFICATIONS,
    });
    yield put({
      user,
      type: Types.GET_USER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_USER_FAIL,
      ...error.response.data,
    });
  }
}

function* getUserToEditRequest() {
  try {
    const idUser = localStorage.getItem("idUser");
    const user = yield requestAPI({
      verb: GET,
      endPoint: GET_USER,
      id: idUser,
    });

    yield put({
      user,
      type: Types.GET_USER_SUCCESS,
    });

    yield put({
      type: Types.GET_PERSONAL_QUESTIONS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_USER_FAIL,
      ...error.response.data,
    });
  }
}

function* getTerms() {
  try {
    const terms = yield requestAPI({
      verb: GET,
      endPoint: GET_TERMS,
    });
    yield put({
      terms,
      type: Types.GET_TERMS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TERMS_FAIL,
      ...error.response.data,
    });
  }
}

function* getPrivacyPolicies() {
  try {
    const privacyPolicies = yield requestAPI({
      verb: GET,
      endPoint: GET_POLICIES_PRIVACY,
    });
    yield put({
      privacyPolicies,
      type: Types.GET_PRIVACY_POLICIES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRIVACY_POLICIES_FAIL,
      ...error.response.data,
    });
  }
}

function* getPersonalQuestions() {
  try {
    const personalQuestions = yield requestAPI({
      verb: GET,
      endPoint: GET_PERSONAL_QUESTIONS,
    });
    yield put({
      personalQuestions: personalQuestions.personal_questions,
      type: Types.GET_PERSONAL_QUESTIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PERSONAL_QUESTIONS_FAIL,
      ...error.response.data,
    });
  }
}

function* getTutorialToken() {
  try {
    const { token } = yield requestAPI({
      verb: GET,
      endPoint: GET_TOKEN_TUTORIAL,
    });
    yield put({
      token,
      type: Types.GET_TUTORIAL_TOKEN_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: `${error.response.data.errors}`,
        buttons: [
          {
            title: "OK",
            event: () => window.location.assign(urls.ROUTES.CONFIGURATIONS),
          },
        ],
      },
    });
    yield put({
      type: Types.GET_TUTORIAL_TOKEN_FAIL,
      ...error.response.data,
    });
  }
}

function* getNotViewdNotifications() {
  try {

    const {data : { size } } = yield API_V2({
      url: GET_NOT_VIEWED_NOTIFICATIONS_SIZE,
      method: "GET",
    });

    yield put({
      notViewdNotifications: size,
      type: Types.GET_NOT_VIEWD_NOTIFICATIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_NOT_VIEWD_NOTIFICATIONS_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_USER_REQUEST, getUserRequest);
  yield takeLatest(Types.GET_USER_TO_EDIT_REQUEST, getUserToEditRequest);
  yield takeLatest(Types.GET_TERMS, getTerms);
  yield takeLatest(Types.GET_PRIVACY_POLICIES, getPrivacyPolicies);
  yield takeLatest(Types.GET_NOT_VIEWD_NOTIFICATIONS, getNotViewdNotifications);
  yield takeLatest(Types.UPDATE_DATA_USER, updateDataUser);
  yield takeLatest(Types.UPDATE_USER_BRAND, updateUserBrand);
  yield takeLatest(Types.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(Types.GET_PERSONAL_QUESTIONS, getPersonalQuestions);
  yield takeLatest(Types.GET_TUTORIAL_TOKEN, getTutorialToken);
}
