import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { style } from "style";
import { useIntl } from "react-intl";
import { Creators as UserActions } from "store/ducks/user";
import { Select } from "components/core/Form/Form";
import { ReactComponent as FleuryLogo } from "assets/img/logoFleury.svg";

import * as S from "./ChooseTeamStyle";

const ChooseTeam = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((state) => state.user);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const dispatchGetUserRequest = useCallback(
    () => dispatch(UserActions.getUserRequest()),
    [dispatch],
  );

  useEffect(() => {
    dispatchGetUserRequest();
  }, [dispatchGetUserRequest]);

  const findIdBrand = (brandName) => {
    const brandId = user.brands.find((brand) => brand.name === brandName).id;
    setDisableButton(false);
    setSelectedBrand(brandId);
  };

  return (
    style.variables.child_profiles?.length > 0 &&
    !isLoading && (
      <S.ChooseTeamContainer>
        <S.LogoCompany>
          <FleuryLogo />
        </S.LogoCompany>
        <S.SelectContainer>
          <Select
            options={user.brands.map((brand) => {
              const { id, name: text, current } = brand;
              return { id, text, current };
            })}
            label={intl.messages["login.textPage.chooseTeam"]}
            onChange={(e) => findIdBrand(e.target.value)}
          />
          <S.Button
            disabled={disableButton}
            type="submit"
            onClick={() => dispatch(UserActions.updateUserBrand(selectedBrand))}
          >
            {intl.messages["general.buttons.access"]}
          </S.Button>
        </S.SelectContainer>
      </S.ChooseTeamContainer>
    )
  );
};

export default ChooseTeam;
