import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";

export const ChooseTeamContainer = styled("section")(
  css({
    position: "relative",
    display: "flex",
    margin: "0 auto",
    flex: "0 1 1160px",
    alignItems: "center",
    justifyItems: "center",

    "@media (max-width: 767px)": {
      width: "100%",
      height: "100%",
      flexDirection: "column",
    },
  }),
);

export const LogoCompany = styled(Flex)(
  css({
    flex: 1,
    padding: "5vh",
    alignItems: "center",
    borderRight: "1px solid #ccc",
    "> svg": {
      width: "90%",
    },
    "@media (max-width: 767px)": {
      flex: "none",
      position: "relative",
      marginTop: "30px",
      padding: 0,
      width: "100%",
      height: "35%",
      display: "flex",
      borderRight: "none",
      alignItems: "center",
      justifyContent: "center",
      "> svg": {
        width: "65%",
      },
    },
  }),
);

export const SelectContainer = styled(Flex)(
  css({
    flex: 1,
    flexDirection: "column",
    padding: "100px",
    maxWidth: "550px",
    "@media (max-width: 767px)": {
      flex: "none",
      width: "100%",
      height: "65%",
      padding: "30px",
      alignItems: "center",
    },
  }),
);

export const Button = styled("button")(
  css({
    fontSize: "14px",
    border: "none",
    color: "white",
    display: "flex",
    fontWeight: 400,
    outline: "none",
    cursor: "pointer",
    maxWidth: "150px",
    bg: "#043081",
    fontFamily: "text",
    borderRadius: "4px",
    padding: "8px 35px",
    textAlign: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    justifyContent: "center",
    textTransform: "uppercase",
    marginTop: "40px",
    "@media (max-width: 405px)": {
      maxWidth: "150px",
    },
    "&: hover": {
      bg: "#00205B",
    },
  }),
);
