/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { history } from "helpers/sharedHelpers";
import Apps from "containers/Apps";
import Login from "containers/Login";
import Logout from "components/presentation/Logout";
import Notifications from "containers/Notifications";
import Profile from "containers/Profile";
import EditProfile from "containers/Profile/EditProfile";
import Mural from "containers/Mural";
import Publication from "containers/Mural/Publication";
import Calendar from "containers/Calendar";
import Store from "containers/Store";
import DetailsProduct from "containers/Store/DetailsProduct";
import Team from "containers/Team";
import ScoreHistory from "containers/ScoreHistory";
import Birthdays from "containers/Birthdays";
import BirthdaysToday from "containers/Birthdays/BirthdaysToday";
import Files from "containers/Files";
import FilesDetails from "containers/Files/FilesDetails";
import GalleryDetails from "containers/Files/GalleryDetails";
import Configurations from "containers/Configurations";
import ChangePassword from "containers/Configurations/ChangePassword";
import TutorialApps from "containers/Configurations/TutorialApps/TutorialApps";
import Academy from "containers/Academy";
import Module from "containers/Academy/Course/Module";
import Course from "containers/Academy/Course";
import Certificate from "containers/Academy/Course/Certificate";
import LoginSaml from "containers/LoginSaml";
import GroupPardini from "containers/GroupPardini";
import SubMenuPardini from "containers/GroupPardini/SubMenuPardini";
import OurCompaniesPardini from "containers/GroupPardini/OurCompaniesPardini";
import OurCompaniesPublication from "containers/GroupPardini/OurCompaniesPardini/OurCompaniesPublication";
import AboutUs from "containers/AboutUs";
import SubAboutUs from "containers/AboutUs/SubAboutUs";
import ChooseTeam from "containers/ChooseTeam/ChooseTeam";
import HoleriteCibra from "containers/HoleriteCibra/HoleriteCibra";
import LoginHolerite from "containers/HoleriteCibra/AuthHolerite/LoginHolerite";
import PrivacyPolicy from "containers/PrivacyPolicy/PrivacyPolicy";
import { style } from "style";
import { useSelector } from "react-redux";
import TeamsLogin from "../containers/TeamsLogin";
import TitlePageComponent from "./TitlePageComponent";
import urls from "./constants/urls";
import { isAuthenticated } from "./isAuthenticated";
import locale from "../locale";

const PrivateRoute = ({ component: Component, title, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <>
          <TitlePageComponent title={title} />
          <Component {...props} />
        </>
      ) : (
        <Redirect
          to={{ pathname: urls.ROUTES.LOGIN, state: { from: props.location } }}
        />
      )
    }
  />
);

const PrivateRoutePardini = ({ component: Component, title, ...rest }) => {
  const adminAccess = localStorage.getItem("admin-access");
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && adminAccess === "true" ? (
          <>
            <TitlePageComponent title={title} />
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: urls.ROUTES.MURAL,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
const PrivateRouteHolerite = ({ component: Component, title, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && style.names.nameApp === "Time Cibra" ? (
        <>
          <TitlePageComponent title={title} />
          <Component {...props} />
        </>
      ) : (
        <Redirect
          to={{
            pathname: urls.ROUTES.MURAL,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const TeamsRouteCibra = ({ component: Component, title, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() && style.names.nameApp === "Time Cibra" ? (
        <>
          <TitlePageComponent title={title} />
          <Component {...props} />
        </>
      ) : (
        <Redirect
          to={{
            pathname: urls.ROUTES.MURAL,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const namePages = locale["pt-BR"];
const nameAppLoginSaml = style.api.samlUrl?.split("/");

export const Routes = () => {
  const { isAuthenticated: guestAuthenticated } = useSelector(
    (state) => state.guestAuth,
  );

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={urls.ROUTES.LOGIN} component={() => <Login />} />
        <Route
          exact
          path={`${urls.ROUTES.LOGIN}/${
            nameAppLoginSaml && nameAppLoginSaml[5]
          }`}
          component={() => <LoginSaml />}
        />
        <Route exact path={urls.ROUTES.LOGOUT} component={() => <Logout />} />
        <Route
          exact
          path={urls.ROUTES.FORGET_PASSWORD}
          component={() => <Login />}
        />
        <Route exact path={urls.ROUTES.SIGNUP} component={() => <Login />} />
        <PrivateRoute
          path={`${urls.ROUTES.MY_NOTIFICATIONS}`}
          component={() => <Notifications />}
          title={namePages.menu.myNotifications}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.PROFILE}/:id`}
          component={() => <Profile />}
        />
        <PrivateRoute
          exact
          path={urls.ROUTES.EDIT_PROFILE}
          component={() => <EditProfile />}
          title={namePages.profile.textPage.myProfile}
        />
        <PrivateRoute
          exact
          path={urls.ROUTES.MURAL}
          component={() => <Mural isFavorite={false} />}
          title={namePages.menu.mural}
        />
        <PrivateRoute
          exact
          path={urls.ROUTES.FAVORITE}
          component={() => <Mural isFavorite />}
          title={namePages.menu.favorite}
        />

        <PrivateRoute
          exact
          path={`${urls.ROUTES.DETAILS_PUBLICATION}/:title/:id`}
          component={() => <Publication />}
        />
        <PrivateRoute
          path={`${urls.ROUTES.CALENDAR}`}
          component={() => <Calendar />}
          title={namePages.menu.calendar}
        />
        {/* <PrivateRoute
          exact
          path={urls.ROUTES.SCORE_HISTORY}
          component={() =>
            localStorage.getItem("roles") !== "guest" ? (
              <ScoreHistory />
            ) : (
              <Redirect to={{ pathname: urls.ROUTES.MURAL }} />
            )
          }
          title={namePages.menu.scoreHistory}
        /> */}
        <PrivateRoute
          exact
          path={urls.ROUTES.ACADEMY}
          component={() => <Academy />}
          title={namePages.menu.academy}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.ACADEMY}/:id`}
          component={() => <Course />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.ACADEMY}/:id/:title`}
          component={() => <Module />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.ACADEMY}/:id/:title/certificado`}
          component={() => <Certificate />}
        />
        {/* <PrivateRoute
          exact
          path={`${urls.ROUTES.STORE}`}
          component={() =>
            localStorage.getItem("roles") !== "guest" ? (
              <Store />
            ) : (
              <Redirect to={{ pathname: urls.ROUTES.MURAL }} />
            )
          }
          title={namePages.menu.store}
        /> */}
        <PrivateRoute
          path={`${urls.ROUTES.DETAILS_PRODUCT}/:title/:id`}
          component={() => <DetailsProduct />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.APPS}`}
          component={() =>
            style.variables.child_profiles.length > 0 &&
            localStorage.getItem("roles") === "legal_person" ? (
              <Redirect to={{ pathname: urls.ROUTES.MURAL }} />
            ) : (
              <Apps />
            )
          }
          title={namePages.menu.apps}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.FILES}/:type`}
          component={() => <Files />}
          title={namePages.menu.arquives}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.FILES}/:name/:id`}
          component={() => <FilesDetails />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.DETAILS_GALLERY}/:name/:id`}
          component={() => <GalleryDetails />}
        />
        <PrivateRoute
          exact
          path={urls.ROUTES.TEAM}
          component={() => <Team />}
          title={namePages.menu.squad}
        />
        <PrivateRoute
          exact
          path={urls.ROUTES.BIRTHDAYS}
          component={() => <Birthdays />}
          title={namePages.menu.birthdays}
        />
        <PrivateRoute
          exact
          path={urls.ROUTES.BIRTHDAYS}
          component={() => <Birthdays />}
        />
        <PrivateRoute
          exact
          path={urls.ROUTES.BIRTHDAYS_TODAY}
          component={() => <BirthdaysToday />}
        />

        <PrivateRoute
          exact
          path={`${urls.ROUTES.CONFIGURATIONS}`}
          component={() => <Configurations />}
          title={namePages.menu.settings}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.CONFIGURATIONS}/alterar-senha`}
          component={() => <ChangePassword />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.CONFIGURATIONS}/como-baixar-o-app`}
          component={() => <TutorialApps />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.GROUP_PARDINI}`}
          component={() => <GroupPardini />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.ABOUT_US}`}
          component={() => <AboutUs />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.SUB_ABOUT_US}/:id`}
          component={() => <SubAboutUs />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.SUB_MENU_PARDINI}/:title/:id`}
          component={() => <SubMenuPardini />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.OUR_COMPANIES_PARDINI}/:title/:id`}
          component={() => <OurCompaniesPardini />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.OUR_COMPANIES_PUBLICATION}/:title/:idSubMenu/:id`}
          component={() => <OurCompaniesPublication />}
        />
        <PrivateRouteHolerite
          exact
          path={`${urls.ROUTES.HOLERITE}`}
          component={() =>
            localStorage.getItem("roles") !== "guest" || guestAuthenticated ? (
              <HoleriteCibra />
            ) : (
              <LoginHolerite />
            )
          }
          title={namePages.menu.holerite}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.CONFIGURATIONS}`}
          component={() => <Configurations />}
          title={namePages.menu.settings}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.CONFIGURATIONS}/alterar-senha`}
          component={() => <ChangePassword />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.CONFIGURATIONS}/como-baixar-o-app`}
          component={() => <TutorialApps />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.GROUP_PARDINI}`}
          component={() => <GroupPardini />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.ABOUT_US}`}
          component={() => <AboutUs />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.SUB_ABOUT_US}/:id`}
          component={() => <SubAboutUs />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.SUB_MENU_PARDINI}/:title/:id`}
          component={() => <SubMenuPardini />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.OUR_COMPANIES_PARDINI}/:title/:id`}
          component={() => <OurCompaniesPardini />}
        />
        <PrivateRoute
          exact
          path={`${urls.ROUTES.OUR_COMPANIES_PUBLICATION}/:title/:idSubMenu/:id`}
          component={() => <OurCompaniesPublication />}
        />
        <Route exact path={urls.ROUTES.PRIVACY_POLICY} component={() => <PrivacyPolicy />} />
        <PrivateRoutePardini
          exact
          path={`${urls.ROUTES.CHOOSE_TEAM}`}
          component={() => <ChooseTeam />}
        />
      </Switch>
    </ConnectedRouter>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.element,
};

PrivateRoute.defaultProps = {
  component: () => {},
};
