import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { GET_SCORE_HISTORY } from "utils/constants/endpoints";
import { requestAPI } from "helpers/requestHelpers";
import { Types } from "../ducks/scoresHistory";

function* getScoresUser() {
  try {
    const scoreUser = yield requestAPI({
      verb: GET,
      endPoint: GET_SCORE_HISTORY,
    });

    yield put({
      scoreUser,
      type: Types.GET_SCORES_HISTORY_USER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_SCORES_HISTORY_USER_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_SCORES_HISTORY_USER, getScoresUser);
}
