import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";

export const ContainerNotFilesFound = styled("div")(
  css({
    display: "flex",
    marginTop: "100px",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "column",

    svg: {
      height: "240px",
      ".cls-1": {
        fill: theme.colors.primaryColor,
        stroke: "none",
      },
      ".cls-2": {
        fill: "#ffc466",
        stroke: "none",
      },
    },
  }),
);

export const ContainerTitle = styled("div")(
  css({
    fontSize: "30px",
    lineHeight: "24px",
    color: "textConfig",
    marginTop: "50px",
  }),
);
