/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import urls from "utils/constants/urls";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfiniteScroll from "react-infinite-scroller";
import { style } from "style";

import { Creators as UserActions } from "store/ducks/user";
import { Creators as MuralActions } from "store/ducks/mural";
import { Creators as PublicationActions } from "store/ducks/detailspublication";
import { Creators as PostCommentsActions } from "store/ducks/postComments";

import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";
import { ReactComponent as EditIcon } from "assets/img/icons/editIcon.svg";
import { ReactComponent as LikeIcon } from "assets/img/icons/heartlike.svg";
import { ReactComponent as IconClose } from "assets/img/icons/iconFechar.svg";
import { ReactComponent as RemoveIcon } from "assets/img/icons/removeIcon.svg";
import { ReactComponent as SendIcon } from "assets/img/icons/send.svg";
import { ReactComponent as ActionOptionsIcon } from "assets/img/icons/actionOptions.svg";
import { smartFormatterDate } from "helpers/sharedHelpers";
import SidebarPublicationSkeleton from "./SidebarPublicationSkeleton";
import CommentsLoadMoreSkeleton from "./CommentsLoadMoreSkeleton";

import * as S from "./SidebarPublicationStyle";

const Publication = ({ isLiked, idMentionedComment }) => {
  const { id } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const commentRef = useRef(null);
  const [showSend, setShowSend] = useState(false);
  const [timerTimeout, setTimerTimeout] = useState(null);
  const [highlight, setHighlight] = useState(true);
  const [idEditCommentPublication, setIdEditCommentPublication] = useState(0);
  const [showUserMarked, setShowUserMarked] = useState(false);
  const [idCommentAnswer, setIdCommentAnswer] = useState(0);
  const [nickname, setNickname] = useState("");
  const commentAreaRef = useRef(null);
  const dispatch = useDispatch();
  const {
    isLoading,
    publication: { liked, likes, userslikes, comments_enabled, likes_enabled },
    sendingComment,
    markUsersPublish,
  } = useSelector(({ detailspublication }) => detailspublication);

  const { publications, isLoading: isLoadingPublication } = useSelector(
    ({ mural }) => mural,
  );

  const {
    comments: canvas_comments,
    hasMore,
    page,
    pagination,
    isLoading: isLoadingComment,
  } = useSelector(({ postComments }) => postComments);

  const publicationsFilterImage = publications
    ?.filter(({ kind }) => kind === "image")
    .slice(0, 3);

  const { user: currentUser } = useSelector(({ user }) => user);

  const commentedUser = canvas_comments?.find(
    ({ user }) => user?.id === currentUser?.id,
  );

  useEffect(() => {
    setHighlight(true);
    clearTimeout(timerTimeout);

    if (!isLoading && !isLoadingComment) {
      commentRef.current?.scrollIntoView({ behavior: "smooth" });

      setTimerTimeout(
        setTimeout(() => {
          setHighlight(false);
        }, 5000),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRef, isLoading, idMentionedComment, isLoadingComment]);

  const dispatchGetMarkUsers = useCallback(
    (userName) => {
      dispatch(PublicationActions.getMarkUsers({ id, userSearch: userName }));
    },
    [dispatch, id],
  );

  const dispatchPublicationsRequest = useCallback(() => {
    dispatch(
      MuralActions.publicationsRequest({
        search: "",
        page: 1,
        favorite: false,
        selectTags: [],
      }),
    );
  }, [dispatch]);

  const dispatchGetUserRequest = useCallback(
    () => dispatch(UserActions.getUserRequest()),
    [dispatch],
  );

  useEffect(() => {
    if (liked === false) {
      isLiked(false);
    }
  });

  useEffect(() => {
    dispatchGetUserRequest();
  }, [liked, canvas_comments, dispatchGetUserRequest]);

  useEffect(() => {
    if (style.variables.gamificationEnabled) {
      dispatchGetMarkUsers();
    } else {
      dispatchPublicationsRequest();
    }
  }, [dispatchGetMarkUsers, dispatchPublicationsRequest]);

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      handleCreateComment(event);
    }
  };

  const handleCreateComment = (evt) => {
    evt.preventDefault();
    const commentPublication = commentAreaRef.current?.value;
    if (commentPublication.trim().length > 0) {
      if (idEditCommentPublication !== 0) {
        dispatch(
          PublicationActions.patchCommentPublication({
            id,
            idEditCommentPublication,
            comment: commentPublication,
          }),
        );
      } else if (idCommentAnswer !== 0) {
        dispatch(
          PublicationActions.postCommentPublication({
            id,
            repliedId: idCommentAnswer,
            comment: commentPublication,
          }),
        );
      } else {
        dispatch(
          PublicationActions.postCommentPublication({
            id,
            comment: commentPublication,
          }),
        );
      }
    }
    setNickname("");
    setIdCommentAnswer(0);
    commentAreaRef.current.value = "";
    setIdEditCommentPublication(0);
  };

  const handleMarkUserComment = () => {
    const value = commentAreaRef.current?.value;
    const lastElement = value[value.length - 1];
    value.length > 0 ? setShowSend(true) : setShowSend(false);

    if (lastElement === "@") {
      setShowUserMarked(true);
    }

    if (lastElement) {
      const markUserIndex = value.lastIndexOf("@", value);
      if (markUserIndex >= 0 && lastElement !== " ") {
        const stringPresent = value.substring(markUserIndex + 1, value.length);
        if (stringPresent.length > 1) {
          dispatchGetMarkUsers(stringPresent);
        }
      }
      return;
    }

    setShowUserMarked(false);
  };

  const handleEditComment = (idComment, valueComment) => {
    commentAreaRef.current.value = valueComment;
    setIdEditCommentPublication(idComment);
  };

  const handleRemoveComment = (idComment) => {
    dispatch(PublicationActions.deleteCommentPublication({ id, idComment }));
  };

  const toggleCollapse = (index) => {
    const target = document.getElementById(`comment-${index}`);
    target.classList.toggle("uncollapsed");
  };

  const handleSelectedUser = (nickName) => {
    const value = commentAreaRef.current?.value;
    const lastElem = commentAreaRef.current?.selectionStart;
    const startElem = value.lastIndexOf("@", lastElem);
    const valueString = `${value.slice(0, startElem)}@${nickName}${value.slice(
      lastElem,
    )}`;
    commentAreaRef.current.value = valueString;
    setShowUserMarked(false);
    dispatchGetMarkUsers();
  };

  const handleCommentDate = (comment) => {
    const currentDate = smartFormatterDate(new Date(), "L").split("/");
    const commentData = smartFormatterDate(comment?.created_at, "L").split("/");

    if(currentDate[2] > commentData[2]){
      return smartFormatterDate(comment?.created_at, "L");
    }
    if (currentDate[1] - commentData[1] >= 4) {
      return smartFormatterDate(comment?.created_at, "L");
    }
    if (currentDate[1] - commentData[1] >= 2) {
      return `Há ${currentDate[1] - commentData[1]} meses`;
    }
    if (currentDate[1] - commentData[1] === 1) {
      return `Há 1 mês`;
    }
    if (currentDate[1] - commentData[1] < 1) {
      if (currentDate[0] - commentData[0] >= 21) {
        return `Há 3 semanas`;
      }
      if (currentDate[0] - commentData[0] >= 14) {
        return `Há 2 semanas`;
      }
      if (currentDate[0] - commentData[0] >= 7) {
        return `Há 1 semana`;
      }
      if (currentDate[0] - commentData[0] < 1) {
        if (
          smartFormatterDate(new Date(), "HH") -
            smartFormatterDate(comment?.created_at, "HH") ===
          1
        ) {
          return `Há 1 hora `;
        }
        if (
          smartFormatterDate(new Date(), "HH") -
            smartFormatterDate(comment?.created_at, "HH") >=
          2
        ) {
          return `Há ${
            smartFormatterDate(new Date(), "HH") -
            smartFormatterDate(comment?.created_at, "HH")
          } horas `;
        }
        if (
          smartFormatterDate(new Date(), "mm") -
            smartFormatterDate(comment?.created_at, "mm") >=
          2
        ) {
          return `Há ${
            smartFormatterDate(new Date(), "mm") -
            smartFormatterDate(comment?.created_at, "mm")
          } minutos`;
        }
        return `Agora mesmo`;
      }
      if (currentDate[0] - commentData[0] === 1) {
        return `Há ${currentDate[0] - commentData[0]} dia`;
      }
      return `Há ${currentDate[0] - commentData[0]} dias`;
    }
    return `Error`;
  };

  const popoverEditComment = (valueComment, idComment, repliesComment) => (
    <S.StyledPopover>
      <S.ContainerPopoveActions>
        <PageTitle noBorder title="O que deseja fazer?" fontSize={2} />
        <S.ContainerOption>
          <S.OptionPopove
            type="button"
            onClick={() => handleEditComment(idComment, valueComment)}
          >
            <EditIcon />
            <S.TextOption>{intl.messages["general.buttons.edit"]}</S.TextOption>
          </S.OptionPopove>
        </S.ContainerOption>
        {!repliesComment && (
          <S.ContainerOption>
            <S.OptionPopove
              type="button"
              onClick={() => handleRemoveComment(idComment)}
            >
              <RemoveIcon />
              <S.TextOption>
                {intl.messages["general.buttons.remove"]}
              </S.TextOption>
            </S.OptionPopove>
          </S.ContainerOption>
        )}
      </S.ContainerPopoveActions>
    </S.StyledPopover>
  );

  const fetchMoreData = () => {
    hasMore &&
      dispatch(
        PostCommentsActions.getPostComments({
          postId: id,
          page: page + 1,
          commentId: idMentionedComment
        }),
      );
  };

  const redirectToUserProfile = (comment, word) => {
    comment.user_markups.map((user)=>{
      if(word.replace("@","") === user.nickname){
        history.push(`${urls.ROUTES.PROFILE}/${user.id}`,)
      }
    })
  }

  const userIsMarkup = (comment, word) => {
    return comment.user_markups.filter(user => user.nickname === word.replace("@","")).length > 0;
  }

  return isLoading ||
    (isLoadingPublication && !style.variables.gamificationEnabled) ? (
    <SidebarPublicationSkeleton />
  ) : (
    <S.ContainerSideBar>
      {localStorage.getItem("roles") !== "guest" && likes_enabled && (
        <S.LikeContainer liked={liked}>
          <S.LikeCounter
            onClick={() =>
              dispatch(
                PublicationActions.getLikeUsersPublication({
                  postId: id,
                }),
              )
            }
          >
            <LikeIcon height={28} />
            {likes > 0 && (
              <>
                <S.UsersLike>
                  {userslikes
                    .filter((item) => item.id !== null && item.image !== null)
                    ?.slice(0, 3)
                    .map(({ id, image }) => (
                      <Avatar key={id} url={image?.url} size="28px" />
                  ))}
                </S.UsersLike>
                {likes <= 3 ? null : <S.Counter>+{likes - 3}</S.Counter>}
              </>
            )}
          </S.LikeCounter>
          <S.LikeButton
            icon={LikeIcon}
            name={liked ? "Descurtir" : "Curtir"}
            variant={
              localStorage.getItem("roles") === "guest"
                ? ["gradient", "disabled"]
                : ["gradient"]
            }
            action={() =>
              dispatch(
                PublicationActions.postLikedPublication({
                  idPublication: id,
                  likedShow: liked,
                }),
              )
            }
          />
        </S.LikeContainer>
      )}
      {style.variables.gamificationEnabled &&
      localStorage.getItem("roles") !== "guest" &&
      !comments_enabled ? (
        <S.ContainerComments>
          <S.CommentsHeader commented={commentedUser}>
            {!!idMentionedComment
              ? pagination.total_count + 1
              : pagination.total_count}{" "}
            {intl.messages["publication.textPage.comments"]}
          </S.CommentsHeader>

          {canvas_comments.length > 0 && (
            <S.OverflowComments>
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                threshold={50}
                // getScrollParent={() => scrollParentRef}
                loader={<CommentsLoadMoreSkeleton />}
              >
                <Flex px={16} flexDirection="column" spaceChildren={4} pb={50}>
                  {canvas_comments?.map(
                    (comment) =>
                      comment.replied_comment_id === null && (
                        <S.Comment
                          key={comment.id}
                          ref={
                            comment.id === idMentionedComment
                              ? commentRef
                              : null
                          }
                          highlight={
                            comment.id === idMentionedComment
                              ? highlight
                              : false
                          }
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Flex spaceChildren={2}>
                              <Avatar
                                url={comment.user?.image?.url}
                                width={32}
                                height={32}
                                onClick={() => history.push(`${urls.ROUTES.PROFILE}/${comment?.user?.id}`)}
                              />
                              <S.Username>{comment.user?.name}</S.Username>
                            </Flex>
                            {comment.user?.id === currentUser.id && (
                              <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="bottom"
                                overlay={popoverEditComment(
                                  comment.body,
                                  comment.id,
                                  comment.replies.length > 0,
                                )}
                              >
                                <S.ContainerActionOptions>
                                  <ActionOptionsIcon />
                                </S.ContainerActionOptions>
                              </OverlayTrigger>
                            )}
                          </Flex>
                          <S.UserComment>
                            {comment.body.split(" ").map((word) =>
                              word[0] === "@" && userIsMarkup(comment, word) ? (
                                <S.MarkUser key={word.id} markedUser onClick={()=>redirectToUserProfile(comment, word)}>
                                  {word}
                                </S.MarkUser>
                              ) : (
                                <S.MarkUser key={word.id}>
                                  {word}
                                </S.MarkUser>
                              ),
                            )}
                          </S.UserComment>
                          <Flex justifyContent="space-between">
                            <S.TimeAnswer>
                              {handleCommentDate(comment)}
                            </S.TimeAnswer>
                            <S.TimeAnswer>
                              <Flex spaceChildren={2}>
                                <S.ViewAnswers
                                  onClick={() => {
                                    setIdCommentAnswer(comment.id);
                                    setNickname(comment.user.nickname);
                                  }}
                                >
                                  Responder
                                </S.ViewAnswers>
                                {comment.replies.length > 0 && (
                                  <S.ViewAnswers
                                    onClick={() => toggleCollapse(comment.id)}
                                  >
                                    {" "}
                                    | Ver Respostas
                                  </S.ViewAnswers>
                                )}
                              </Flex>
                            </S.TimeAnswer>
                          </Flex>
                          <S.ContainerAnswers id={`comment-${comment.id}`}>
                            {comment.replies.length > 0 &&
                              comment.replies.map((answer) => (
                                <Flex
                                  key={answer.id}
                                  ml={36}
                                  flexDirection="column"
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Flex spaceChildren={2}>
                                      <Avatar
                                        url={answer.user?.image?.url}
                                        width={32}
                                        height={32}
                                      />
                                      <S.Username>
                                        {answer.user?.name}
                                      </S.Username>
                                    </Flex>
                                    {answer.user?.id === currentUser.id && (
                                      <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="bottom"
                                        overlay={popoverEditComment(
                                          answer.body,
                                          answer.id,
                                        )}
                                      >
                                        <S.ContainerActionOptions>
                                          <ActionOptionsIcon />
                                        </S.ContainerActionOptions>
                                      </OverlayTrigger>
                                    )}
                                  </Flex>
                                  <S.UserComment id={`reply-${answer.id}`}>
                                    {answer.body}
                                  </S.UserComment>
                                </Flex>
                              ))}
                          </S.ContainerAnswers>
                        </S.Comment>
                      ),
                  )}
                </Flex>
              </InfiniteScroll>
            </S.OverflowComments>
          )}

          <S.CommentForm onSubmit={handleCreateComment}>
            <Flex width={1} p={2} flexDirection="column" alignItems="center">
              <Flex
                spaceChildren={1}
                justifyContent="space-evenly"
                alignItems="center"
                width={0.9}
              >
                <S.ContainerInputArea>
                  <S.ComponentExitComment
                    showCommentPublication={
                      idEditCommentPublication || idCommentAnswer
                    }
                  >
                    <S.ButtonCloseComment
                      onClick={() => {
                        setNickname("");
                        setIdCommentAnswer(0);
                        setIdEditCommentPublication(0);
                        commentAreaRef.current.value = "";
                      }}
                      type="button"
                    >
                      <IconClose />
                    </S.ButtonCloseComment>
                    <S.TextExitComment>
                      {idCommentAnswer !== 0
                        ? `Respondendo @${nickname}`
                        : "Editando"}
                    </S.TextExitComment>
                  </S.ComponentExitComment>
                  <S.ComponentUsersMarked userMarked={showUserMarked}>
                    {markUsersPublish.length > 0 &&
                      markUsersPublish.map((user) => (
                        <S.ContainerUser
                          key={user.id}
                          onClick={() => handleSelectedUser(user.nickname)}
                        >
                          <Avatar url={user.image.url} size={28} />
                          <S.NameUser>{user.name}</S.NameUser>
                        </S.ContainerUser>
                      ))}
                  </S.ComponentUsersMarked>
                  <S.CommentInput
                    ref={commentAreaRef}
                    onChange={handleMarkUserComment}
                    onKeyDown={handleKeyDown}
                    disabled={localStorage.getItem("roles") === "guest"}
                    placeholder={
                      localStorage.getItem("roles") === "guest"
                        ? intl.messages[
                            "publication.textPage.commentInputPlaceholderUserGuest"
                          ]
                        : intl.messages[
                            "publication.textPage.commentInputPlaceholder"
                          ]
                    }
                    showCommentPublication={
                      idEditCommentPublication || idCommentAnswer
                    }
                  />
                </S.ContainerInputArea>
                <S.SendComment
                  isLoading={sendingComment}
                  disabled={
                    !showSend &&
                    commentAreaRef.current?.value.trim().length === 0
                  }
                >
                  {!sendingComment && <SendIcon />}
                </S.SendComment>
              </Flex>
            </Flex>
          </S.CommentForm>
        </S.ContainerComments>
      ) : (
        <S.ContainerPublicationsSideBar>
          <PageTitle
            title={intl.messages["publication.textPage.commentsTitle"]}
          />
          {publicationsFilterImage?.map(({ id, title, data }) => (
            <S.CardPublication
              onClick={() =>
                history.push(
                  `${urls.ROUTES.DETAILS_PUBLICATION}/${title}/${id}`,
                )
              }
            >
              <S.TittlePublication>{title}</S.TittlePublication>
              <S.ContentImage>
                <S.ImagePublication src={data} alt="" />
              </S.ContentImage>
            </S.CardPublication>
          ))}
        </S.ContainerPublicationsSideBar>
      )}
    </S.ContainerSideBar>
  );
};

Publication.defaultProps = {
  isLiked: null,
  idMentionedComment: null,
};

Publication.propTypes = {
  isLiked: PropTypes.func,
  idMentionedComment: PropTypes.number,
};

export default Publication;
