import React, { useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { style } from "style";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";

import urls from "utils/constants/urls";
import { useAnalytics } from "utils/hooks";
import { Creators as AcademyActions } from "store/ducks/academy";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import { history } from "helpers/sharedHelpers";
import { PageTitle } from "components/core/Typography/Typography";
import { ReactComponent as NoResults } from "assets/img/hasNoResults.svg";
import MyCourses from "./Sidebar/MyCourses";
import AcademySkeleton from "./AcademySkeleton";
import AcademyMoreSkeleton from "./AcademyMoreSkeleton";

import * as S from "./AcademyStyle";

useAnalytics("screen_academy");

const Academy = () => {
  const intl = useIntl();
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const [scrollParentRef, setScrollParentRef] = useState();
  const { coursesList, hasMore, page, isLoading } = useSelector(
    ({ academy }) => academy,
  );

  useEffect(() => {
    dispatch(AcademyActions.getCourses());
  }, [dispatch]);

  const fetchMoreData = () => {
    // eslint-disable-next-line no-unused-expressions
    hasMore && dispatch(AcademyActions.getCourses());
  };

  return (
    <Portal
      sidebarContext={<MyCourses />}
      setRef={setScrollParentRef}
      textBtnInSideBar={intl.messages["academy.sidebar.title"]}
    >
      <Flex flexDirection="column" paddingBottom>
        <S.ContentPagetitle>
          <PageTitle noBorder title={intl.messages["menu.academy"]} />
        </S.ContentPagetitle>
        {page === 1 && isLoading ? (
          <AcademySkeleton />
        ) : (
          <Flex flexDirection="column" p={3}>
            <S.Subtitles>
              <h3>Legenda:</h3>
              <S.Status bg="secondaryColor">Disponíveis</S.Status>
              <S.Status bg="blue">Em progresso</S.Status>
              <S.Status bg="greenDark">Concluídos</S.Status>
            </S.Subtitles>
            <>
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                threshold={10}
                getScrollParent={() => scrollParentRef}
                loader={<AcademyMoreSkeleton />}
              >
                <S.CoursesList ref={listRef}>
                  {coursesList?.map(
                    ({
                      id,
                      title,
                      image,
                      modules_amount: moduleLength,
                      description,
                      available,
                      user_status: { status },
                      modality
                    }) =>
                      available && (
                        <S.CourseCard
                          onClick={() =>
                            history.push(`${urls.ROUTES.ACADEMY}/${id}`)
                          }
                        >
                          <img src={image.url} alt={title} />
                          <S.CourseInfo>
                            <h1
                              title={
                                title.length > 44
                                  ? title
                                  : ""
                              }
                            >
                              {title.length > 44
                              ? title.slice(0,44) + '...'
                              : title}
                            </h1>
                            <S.ModulesCount status={status}>
                              {moduleLength}{" "}
                              {moduleLength > 1 ? "Módulos" : "Módulo"}
                            </S.ModulesCount>
                            {
                              modality === "online" ?
                                <p>{description}</p>
                                :(
                                  title.length > 44 ?
                                    <p title={description}>{description.slice(0,22) + '...'}</p>
                                    : <p>{description}</p>
                                )
                            }
                          </S.CourseInfo>
                        </S.CourseCard>
                      ),
                  )}
                </S.CoursesList>
              </InfiniteScroll>
              {style.variables.child_profiles?.length > 0 &&
                !coursesList?.length > 0 && (
                  <S.ContainerNoResults>
                    <NoResults />
                    <S.ContainerTittle>
                      {intl.messages["academy.textPage.notCursesFound"]}
                    </S.ContainerTittle>
                  </S.ContainerNoResults>
                )}
            </>
          </Flex>
        )}
      </Flex>
    </Portal>
  );
};

export default Academy;
