import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { style } from "style";
import urls from "utils/constants/urls";
import { useAnalytics } from "utils/hooks";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import { Switch } from "components/core/Form/Form";
import { Creators as TermsActions } from "store/ducks/user";
import { PageTitle } from "components/core/Typography/Typography";
import Button from "components/core/Button/Button";

import Avatar from "components/core/Avatar/Avatar";
import * as S from "./ConfigurationsStyle";

useAnalytics("screen_configuration");

const Configurations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const idUser = localStorage.getItem("idUser");
  const localConfig =
    JSON.parse(localStorage.getItem(`localConfig-${idUser}`)) || {};
  const [config, setConfig] = useState({
    notification: localConfig?.notification || false,
    videoAutoplay: localConfig?.videoAutoplay || false,
  });
  const { terms, privacyPolicies } = useSelector((state) => state.user);
  const url = terms?.file?.url;
  const urlPrivacyPolicies = privacyPolicies?.file?.url;

  localStorage.setItem(`localConfig-${idUser}`, JSON.stringify(config));

  const dispatchGetTerms = useCallback(
    () => dispatch(TermsActions.getTerms()),
    [dispatch],
  );

  const dispatchGetPrivacyPolicies = useCallback(
    () => dispatch(TermsActions.getPrivacyPolicies()),
    [dispatch],
  );

  useEffect(() => {
    dispatchGetTerms();
    dispatchGetPrivacyPolicies();
  }, [dispatchGetTerms, dispatchGetPrivacyPolicies]);

  const [generatedToken, setGeneratedToken] = useState("");
  const [wasCopied, setWasCopied] = useState(false);

  const generateTeamsToken = () => {
    const teamsToken = `${localStorage.getItem(
      "idUser",
    )}/${localStorage.getItem("sessionIndex")}/${localStorage.getItem(
      "access-token",
    )}/${localStorage.getItem("client")}/${localStorage.getItem(
      "uid",
    )}/${localStorage.getItem("resource-type")}/${localStorage.getItem(
      "host-header",
    )}`;

    setGeneratedToken(Buffer.from(teamsToken).toString("base64"));
  };

  const copyTokenClipboard = () => {
    navigator.clipboard.writeText(generatedToken);
    setWasCopied(true);
  };

  const boxConfigLink = (hrefUrl, hrefTarget, msgText) => (
    <S.BoxConfig>
      <S.LinkPassword href={`${hrefUrl}`} target={hrefTarget}>
        <Flex justifyContent="space-between">
          <S.TextLink>{msgText}</S.TextLink>
          <S.ArrowLink />
        </Flex>
      </S.LinkPassword>
    </S.BoxConfig>
  );

  return (
    <Portal>
      <S.ConfigContainer flexDirection="column">
        <PageTitle title={intl.messages["menu.settings"]} />
        <Flex flexDirection="column" p={3} marginBottom="120px">
          <S.BoxConfig>
            <Flex flexDirection="row">
              <S.AvatarContainer>
                <Avatar size="100%" url={user?.image?.url} />
              </S.AvatarContainer>
              <S.TextContainer>
                <S.UserName>{user.name}</S.UserName>
                <S.GoProfileLink
                  to={`${urls.ROUTES.PROFILE}/${idUser}`}
                  key={idUser}
                >
                  <S.GoProfile>
                    {intl.messages["settings.textPage.seeProfile"]}
                  </S.GoProfile>
                </S.GoProfileLink>
              </S.TextContainer>
            </Flex>
          </S.BoxConfig>
          <S.BoxConfig>
            <Flex justifyContent="space-between">
              <S.ContainerTextSwitch>
                <S.TextLink>
                  {intl.messages["settings.textPage.autoplayOnVideos"]}
                </S.TextLink>
                <Switch
                  active={config.videoAutoplay}
                  onChange={() =>
                    setConfig({
                      ...config,
                      videoAutoplay: !config.videoAutoplay,
                    })
                  }
                />
              </S.ContainerTextSwitch>
            </Flex>
          </S.BoxConfig>
          {style.names.nameApp !== "MRN" &&
            localStorage.getItem("roles") !== "guest" &&
            !style.api.samlUrl &&
            boxConfigLink(
              `${urls.ROUTES.CONFIGURATIONS}/alterar-senha`,
              "_self",
              intl.messages["settings.textPage.updatePassword"],
            )}
          {boxConfigLink(
            `${urls.ROUTES.CONFIGURATIONS}/como-baixar-o-app`,
            "_self",
            "Como Baixar o App",
          )}
          {boxConfigLink(
            url,
            "_blank",
            intl.messages["settings.textPage.termsAndConditions"],
          )}
          {style.variables.hasPrivacyPolicies &&
            boxConfigLink(
              urlPrivacyPolicies,
              "_blank",
              intl.messages["settings.textPage.privacyPolicy"],
            )}
          {style.names.nameApp === "Time Cibra" && (
            <S.BoxConfig>
              <S.GenerateTokenRow>
                <S.TextLink>Token de autenticação Microsoft Teams</S.TextLink>
                {generatedToken ? (
                  <Button
                    variant="medium"
                    name={wasCopied ? "Copiado!" : "Copiar"}
                    onClick={copyTokenClipboard}
                  />
                ) : (
                  <Button
                    variant="medium"
                    name="Gerar"
                    onClick={generateTeamsToken}
                  />
                )}
              </S.GenerateTokenRow>
              {generatedToken && (
                <Flex>
                  <S.TokenText>{generatedToken}</S.TokenText>
                </Flex>
              )}
            </S.BoxConfig>
          )}
        </Flex>
      </S.ConfigContainer>
    </Portal>
  );
};

export default Configurations;
