import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getUserRequest: ["payload"],
  getUserSuccess: ["payload", "isLoading"],
  getUserFail: ["isLoading", "error"],

  getUserToEditRequest: [],

  getNotViewdNotifications: [],
  getNotViewdNotificationsSuccess: ["payload", "isLoading"],
  getNotViewdNotificationsFail: ["isLoading", "error"],

  getTerms: [],
  getTermsSuccess: ["payload", "isLoading"],
  getTermsFail: ["isLoading", "error"],

  getPrivacyPolicies: [],
  getPrivacyPoliciesSuccess: ["payload", "isLoading"],
  getPrivacyPoliciesFail: ["isLoading", "error"],

  getPersonalQuestions: [],
  getPersonalQuestionsSuccess: ["payload", "isLoading"],
  getPersonalQuestionsFail: ["isLoading", "error"],

  getTutorialToken: [],
  getTutorialTokenSuccess: ["payload", "isLoading"],
  getTutorialTokenFail: ["isLoading", "error"],

  updateDataUser: ["payload"],
  updateDataUserSuccess: ["payload", "isLoading"],
  updateDataUserFail: ["isLoading", "error"],

  updateUserBrand: ["payload"],
  updateUserBrandSuccess: ["payload", "isLoading"],
  updateUserBrandFail: ["isLoading", "error"],

  updatePassword: ["payload"],
  updatePasswordSuccess: ["payload", "isLoading"],
  updatePasswordFail: ["isLoading", "error"],
});
const INITIAL_STATE = {
  user: [],
  terms: {},
  personalQuestions: [],
  notViewdNotifications: 0,
  isLoading: true,
  isLoadingUpdate: false,
  currentPassError: false,
  isLoadingPassword: false,
};

const getUserRequest = () => ({ ...INITIAL_STATE });
const getUserToEditRequest = (state) => ({ ...state });

const getUserSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getUserFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getNotViewdNotifications = (state) => ({ ...state });

const getNotViewdNotificationsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getNotViewdNotificationsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getTerms = (state) => ({ ...state });

const getTermsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getTermsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getPrivacyPolicies = (state) => ({ ...state });

const getPrivacyPoliciesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getPrivacyPoliciesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getPersonalQuestions = (state) => ({ ...state });

const getPersonalQuestionsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getPersonalQuestionsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getTutorialToken = (state) => ({ ...state });

const getTutorialTokenSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  ...payload,
});
const getTutorialTokenFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const updateDateUser = (payload) => ({
  ...payload,
  isLoadingUpdate: true,
});

const updateDateUserSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingUpdate: false,
});
const updateDateUserFail = (state, error) => ({
  ...state,
  isLoadingUpdate: false,
  ...error,
});

const updateUserBrand = (payload) => ({
  ...payload,
  isLoadingUpdate: true,
});

const updateUserBrandSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingUpdate: false,
});
const updateUserBrandFail = (state, error) => ({
  ...state,
  isLoadingUpdate: false,
  ...error,
});

const updatePassword = (payload) => ({
  ...payload,
  isLoadingPassword: true,
});
const updatePasswordSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingPassword: false,
});
const updatePasswordFail = (state, error) => ({
  ...state,
  isLoadingPassword: false,
  ...error,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAIL]: getUserFail,

  [Types.GET_USER_TO_EDIT_REQUEST]: getUserToEditRequest,

  [Types.GET_NOT_VIEWD_NOTIFICATIONS]: getNotViewdNotifications,
  [Types.GET_NOT_VIEWD_NOTIFICATIONS_SUCCESS]: getNotViewdNotificationsSuccess,
  [Types.GET_NOT_VIEWD_NOTIFICATIONS_FAIL]: getNotViewdNotificationsFail,

  [Types.GET_TERMS]: getTerms,
  [Types.GET_TERMS_SUCCESS]: getTermsSuccess,
  [Types.GET_TERMS_FAIL]: getTermsFail,

  [Types.GET_PRIVACY_POLICIES]: getPrivacyPolicies,
  [Types.GET_PRIVACY_POLICIES_SUCCESS]: getPrivacyPoliciesSuccess,
  [Types.GET_PRIVACY_POLICIES_FAIL]: getPrivacyPoliciesFail,

  [Types.GET_PERSONAL_QUESTIONS]: getPersonalQuestions,
  [Types.GET_PERSONAL_QUESTIONS_SUCCESS]: getPersonalQuestionsSuccess,
  [Types.GET_PERSONAL_QUESTIONS_FAIL]: getPersonalQuestionsFail,

  [Types.GET_TUTORIAL_TOKEN]: getTutorialToken,
  [Types.GET_TUTORIAL_TOKEN_SUCCESS]: getTutorialTokenSuccess,
  [Types.GET_TUTORIAL_TOKEN_FAIL]: getTutorialTokenFail,

  [Types.UPDATE_DATA_USER]: updateDateUser,
  [Types.UPDATE_DATA_USER_SUCCESS]: updateDateUserSuccess,
  [Types.UPDATE_DATA_USER_FAIL]: updateDateUserFail,

  [Types.UPDATE_USER_BRAND]: updateUserBrand,
  [Types.UPDATE_USER_BRAND_SUCCESS]: updateUserBrandSuccess,
  [Types.UPDATE_USER_BRAND_FAIL]: updateUserBrandFail,

  [Types.UPDATE_PASSWORD]: updatePassword,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAIL]: updatePasswordFail,
});
