import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getDetailsPublication: ["payload"],
  getDetailsPublicationSuccess: ["payload", "isLoading"],
  getDetailsPublicationFail: ["isLoading", "error"],

  postLikedPublication: ["payload"],
  postLikedPublicationSuccess: ["isLoading"],
  postLikedPublicationFail: ["isLoading", "error"],

  postCommentPublication: ["payload"],
  postCommentPublicationSuccess: ["newComment", "isLoading"],
  postCommentPublicationFail: ["isLoading", "error"],

  postFormAnswer: ["payload"],
  postFormAnswerSuccess: ["sendingForm"],
  postFormAnswerFail: ["sendingForm", "error"],

  postSurveyAnswer: ["payload"],
  postSurveyAnswerSuccess: ["sendingForm"],
  postSurveyAnswerFail: ["sendingForm", "error"],

  patchCommentPublication: ["payload"],
  patchCommentPublicationSuccess: ["patchComment", "isLoading"],
  patchCommentPublicationFail: ["isLoading", "error"],

  deleteCommentPublication: ["payload"],
  deleteCommentPublicationSuccess: ["deleteComment", "isLoading"],
  deleteCommentPublicationFail: ["isLoading", "error"],

  getMarkUsers: ["payload"],
  getMarkUsersSuccess: ["markUsers", "isLoading"],
  getMarkUsersFail: ["isLoading", "error"],

  postFavoritePublicationPageRequest: ["payload"],
  postFavoritePublicationPageSuccess: ["payload", "isLoading"],
  postFavoritePublicationPageFail: ["isLoading", "error"],

  deleteFavoritePublicationPageRequest: ["payload"],
  deleteFavoritePublicationPageSuccess: ["payload", "isLoading"],
  deleteFavoritePublicationPageFail: ["isLoading", "error"],

  getLikeUsersPublication: ["payload"],
  getLikeUsersPublicationSuccess: ["payload", "isLoading"],
  getLikeUsersPublicationFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  publication: {},
  isLoading: true,
  sendingComment: false,
  sendingForm: false,
  formSent: null,
  error: [],
  markUsersPublish: [],
  likesViewList:[],
  hasMoreLikesViewList: true,
  isLoadingLikesViewList: false,
  pageLikesView: 1,
};

const getDetailsPublication = () => ({
  ...INITIAL_STATE,
});

const getDetailsPublicationSuccess = (state, { publication }) => ({
  ...state,
  publication,
  isLoading: false,
});

const getDetailsPublicationFail = (state) => ({
  ...state,
  isLoading: false,
});

const postLikedPublication = (state) => ({ ...state });

const postLikedPublicationSuccess = (state, { currentUser }) => ({
  ...state,
  publication: {
    ...state.publication,
    liked: !state.publication.liked,
    likes: !state.publication.liked
      ? state.publication.likes + 1
      : state.publication.likes - 1,
    userslikes: state.publication.liked
      ? state.publication.userslikes.filter(
          (user) => user.id !== currentUser.id,
        )
      : [...state.publication.userslikes, currentUser],
  },
  isLoading: false,
});

const postLikedPublicationFail = (state) => ({
  ...state,
  isLoading: false,
});

const postCommentPublication = (state) => ({
  ...state,
  sendingComment: true,
});

const postCommentPublicationSuccess = (state, { newComment }) => ({
  ...state,
  publication: {
    ...state.publication,
    canvas_comments: [newComment, ...state.publication.canvas_comments],
  },
  isLoading: false,
  sendingComment: false,
});

const postCommentPublicationFail = (state) => ({
  ...state,
  isLoading: false,
  sendingComment: false,
});

const postFormAnswer = (state) => ({
  ...state,
  sendingForm: true,
  formSent: null,
});

const postFormAnswerSuccess = (state) => ({
  ...state,
  sendingForm: false,
  formSent: true,
});

const postFormAnswerFail = (state, { error }) => ({
  ...state,
  sendingForm: false,
  formSent: false,
  error,
});

const postSurveyAnswer = (state) => ({
  ...state,
  sendingForm: true,
  formSent: null,
});

const postSurveyAnswerSuccess = (state) => ({
  ...state,
  sendingForm: false,
  formSent: true,
});

const postSurveyAnswerFail = (state, { error }) => ({
  ...state,
  sendingForm: false,
  formSent: false,
  error,
});

const patchCommentPublication = (state) => ({
  ...state,
  sendingComment: true,
});

const patchCommentPublicationSuccess = (state, { patchComment }) => {
  const patchState = state.publication.canvas_comments.map((comment) => {
    if (comment.id === patchComment.id) {
      // eslint-disable-next-line no-param-reassign
      comment.body = patchComment.body;
    }
    return comment;
  });

  return {
    ...state,
    publication: {
      ...state.publication,
      canvas_comments: patchState,
    },
    isLoading: false,
    sendingComment: false,
  };
};

const patchCommentPublicationFail = (state) => ({
  ...state,
  isLoading: false,
  sendingComment: false,
});

const deleteCommentPublication = (state) => ({
  ...state,
  sendingComment: true,
});

const deleteCommentPublicationSuccess = (state, { deletedCommentId }) => {
  const commentDeleted = [];
  state.publication.canvas_comments.map((comment) => {
    if (comment.id !== deletedCommentId) {
      return commentDeleted.push(comment);
    }
    return false;
  });

  return {
    ...state,
    publication: {
      ...state.publication,
      canvas_comments: commentDeleted,
    },
    isLoading: false,
    sendingComment: false,
  };
};

const deleteCommentPublicationFail = (state) => ({
  ...state,
  isLoading: false,
  sendingComment: false,
});

const getMarkUsers = (state) => ({
  ...state,
});

const getMarkUsersSuccess = (state, { markUsers }) => ({
  ...state,

  markUsersPublish: markUsers,
});

const getMarkUsersFail = (state) => ({
  ...state,
});

const postFavoritePublicationPageRequest = (state) => ({
  ...state,
});

const postFavoritePublicationPageSuccess = (state) => {
  const { publication } = state;
  return {
    ...state,
    publication: { ...publication, favorite: true },
  };
};
const postFavoritePublicationPageFail = (state, error) => ({
  ...state,
  ...error,
});

const deleteFavoritePublicationPageRequest = (state) => ({
  ...state,
});
const deleteFavoritePublicationPageSuccess = (state) => {
  const { publication } = state;
  return {
    ...state,
    publication: { ...publication, favorite: false },
  };
};
const deleteFavoritePublicationPageFail = (state, error) => ({
  ...state,
  ...error,
});

const getLikeUsersPublication = (state) => ({
  ...state,
  isLoadingLikesViewList: true,
});
const getLikeUsersPublicationSuccess = (state, payload) => {
  return {
    ...state,
    ...payload,
    isLoadingLikesViewList: false,
  };
};
const getLikeUsersPublicationFail = (state, error) => ({
  ...state,
  ...error,
  isLoadingLikesViewList: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_DETAILS_PUBLICATION]: getDetailsPublication,
  [Types.GET_DETAILS_PUBLICATION_SUCCESS]: getDetailsPublicationSuccess,
  [Types.GET_DETAILS_PUBLICATION_FAIL]: getDetailsPublicationFail,
  [Types.POST_LIKED_PUBLICATION]: postLikedPublication,
  [Types.POST_LIKED_PUBLICATION_SUCCESS]: postLikedPublicationSuccess,
  [Types.POST_LIKED_PUBLICATION_FAIL]: postLikedPublicationFail,
  [Types.POST_COMMENT_PUBLICATION]: postCommentPublication,
  [Types.POST_COMMENT_PUBLICATION_SUCCESS]: postCommentPublicationSuccess,
  [Types.POST_COMMENT_PUBLICATION_FAIL]: postCommentPublicationFail,
  [Types.POST_FORM_ANSWER]: postFormAnswer,
  [Types.POST_FORM_ANSWER_SUCCESS]: postFormAnswerSuccess,
  [Types.POST_FORM_ANSWER_FAIL]: postFormAnswerFail,
  [Types.POST_SURVEY_ANSWER]: postSurveyAnswer,
  [Types.POST_SURVEY_ANSWER_SUCCESS]: postSurveyAnswerSuccess,
  [Types.POST_SURVEY_ANSWER_FAIL]: postSurveyAnswerFail,
  [Types.PATCH_COMMENT_PUBLICATION]: patchCommentPublication,
  [Types.PATCH_COMMENT_PUBLICATION_SUCCESS]: patchCommentPublicationSuccess,
  [Types.PATCH_COMMENT_PUBLICATION_FAIL]: patchCommentPublicationFail,
  [Types.DELETE_COMMENT_PUBLICATION]: deleteCommentPublication,
  [Types.DELETE_COMMENT_PUBLICATION_SUCCESS]: deleteCommentPublicationSuccess,
  [Types.DELETE_COMMENT_PUBLICATION_FAIL]: deleteCommentPublicationFail,
  [Types.GET_MARK_USERS]: getMarkUsers,
  [Types.GET_MARK_USERS_SUCCESS]: getMarkUsersSuccess,
  [Types.GET_MARK_USERS_FAIL]: getMarkUsersFail,
  [Types.POST_FAVORITE_PUBLICATION_PAGE_REQUEST]: postFavoritePublicationPageRequest,
  [Types.POST_FAVORITE_PUBLICATION_PAGE_SUCCESS]: postFavoritePublicationPageSuccess,
  [Types.POST_FAVORITE_PUBLICATION_PAGE_FAIL]: postFavoritePublicationPageFail,
  [Types.DELETE_FAVORITE_PUBLICATION_PAGE_REQUEST]: deleteFavoritePublicationPageRequest,
  [Types.DELETE_FAVORITE_PUBLICATION_PAGE_SUCCESS]: deleteFavoritePublicationPageSuccess,
  [Types.DELETE_FAVORITE_PUBLICATION_PAGE_FAIL]: deleteFavoritePublicationPageFail,
  [Types.GET_LIKE_USERS_PUBLICATION]: getLikeUsersPublication,
  [Types.GET_LIKE_USERS_PUBLICATION_SUCCESS]: getLikeUsersPublicationSuccess,
  [Types.GET_LIKE_USERS_PUBLICATION_FAIL]: getLikeUsersPublicationFail,
});
