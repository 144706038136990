import { takeLatest, put, select } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import {
  GET_USER,
  GET_ALL_TEAMS,
  GET_SECTOR_USERS,
  GET_BRAND_FILTER,
} from "utils/constants/endpoints";
import { requestAPI } from "helpers/requestHelpers";
import { Types } from "../ducks/team";
import { API_V2 } from "../../utils/API/API";

const getSectorsActual = (state) => state.team.sectors;
const getSectorUsersAtual = (state) => state.team.users;

function* getAllSectorsBrandFilter({ payload }) {
  const { page, search, team } = payload;
  try {
    const searchEscaped = encodeURIComponent(search);
    const { data: { pagination, sectors } } = yield API_V2({
      url: searchEscaped
        ? `${GET_ALL_TEAMS}/brand_sectors?brand_name=${team}&page=${page}&search=${searchEscaped}`
        : `${GET_ALL_TEAMS}/brand_sectors?brand_name=${team}&page=${page}`,
      method: "GET",
    });
    const parsedSectors = yield sectors.map(({ id, name, users, users_size }) => ({
      teamId: id,
      teamName: name,
      teamMembers: users.map((member) => ({
        id: member.id,
        name: member.name,
        image: member.image.url,
        birthday: member.datebirthday,
      })),
      teamUsersSize: users_size
    }));
    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1
        ? []
        : yield select(getSectorsActual);
    const newList = actualList.concat(parsedSectors);
    const hasMoreSectors = !!pagination.next_page;
    yield put({
      sectors: newList,
      hasMoreSectors,
      pageSector: page,
      searchingForSector: search,
      type: Types.GET_ALL_SECTORS_BRAND_FILTER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_ALL_SECTORS_BRAND_FILTER_FAIL,
      ...error?.response?.data,
    });
  }
}

function* getSectorUsers({ payload }) {
  const { id, page, search } = payload;
  try {
    const searchEscaped = encodeURIComponent(search);
    const { data: { pagination, users } } = yield API_V2({
      url: searchEscaped
        ? `${GET_SECTOR_USERS}?sector_id=${id}&page=${page}&search=${searchEscaped}`
        : `${GET_SECTOR_USERS}?sector_id=${id}&page=${page}`,
      method: "GET",
    });
    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1
        ? []
        : yield select(getSectorUsersAtual);
    const newList = actualList.concat(users);
    const hasMoreUsers = !!pagination.next_page;
    yield put({
      users: newList,
      hasMoreUsers,
      pageUser: page,
      searchingForUser: search,
      type: Types.GET_SECTOR_USERS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_SECTOR_USERS_FAIL,
      ...error?.response?.data,
    });
  }
}

function* getAllTeams() {
  try {
    const sectors = yield requestAPI({
      verb: GET,
      endPoint: GET_ALL_TEAMS,
    });

    const allTeams = yield sectors.map(({ id, name, users }) => ({
      teamId: id,
      teamName: name,
      teamMembers: users.map((member) => ({
        id: member.id,
        name: member.name,
        image: member.image.url,
        birthday: member.datebirthday,
      })),
    }));

    yield put({
      allTeams,
      type: Types.GET_ALL_TEAMS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_ALL_TEAMS_FAIL,
      ...error.response.data,
    });
  }
}

function* getProfile({ id }) {
  try {
    const selectedProfile = yield requestAPI({
      verb: GET,
      endPoint: GET_USER(id),
    });

    yield put({
      selectedProfile,
      type: Types.GET_PROFILE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PROFILE_FAIL,
      ...error.response.data,
    });
  }
}

function* getAllBrandFilter({ nameTeam }) {
  try {
    const selectedBrand = yield requestAPI({
      verb: GET,
      endPoint: `${GET_BRAND_FILTER}?brand_name=${nameTeam}`,
    });

    const allBrand = yield selectedBrand.map(({ id, name, users }) => ({
      teamId: id,
      teamName: name,
      teamMembers: users.map((member) => ({
        id: member.id,
        name: member.name,
        image: member.image.url,
        birthday: member.datebirthday,
      })),
    }));
    yield put({
      allTeams: allBrand,
      type: Types.GET_ALL_BRAND_FILTER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_ALL_BRAND_FILTER_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_ALL_SECTORS_BRAND_FILTER, getAllSectorsBrandFilter);
  yield takeLatest(Types.GET_SECTOR_USERS, getSectorUsers);
  yield takeLatest(Types.GET_ALL_TEAMS, getAllTeams);
  yield takeLatest(Types.GET_PROFILE, getProfile);
  yield takeLatest(Types.GET_ALL_BRAND_FILTER, getAllBrandFilter);
}
