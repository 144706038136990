import styled from "styled-components";
import css from "@styled-system/css";

import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button";
import Popover from "react-bootstrap/Popover";

export const ContainerSideBar = styled("div")(
  css({
    width: "100%",
    height: "100vh",
  }),
);

export const LikeContainer = styled(Flex)(
  css({
    px: 16,
    py: 12,
    justifyContent: "space-between",
    borderBottom: "1px solid",
    borderColor: "grayDefaultColor",
    "@media (max-width:1024px)": {
      justifyContent: "center",
      borderBottom: "none",
      px: 0,
      py: 0,
    },
  }),
  ({ liked }) =>
    liked &&
    css({
      "> div svg path": {
        stroke: theme.colors.secondaryColor,
        fill: theme.colors.secondaryColor,
      },
      "> button svg path": {
        fill: "white",
      },
    }),
);

export const LikeCounter = styled(Flex)(
  css({
    alignItems: "center",
    px: 20,
    py: 12,
    bg: "paleGrey",
    borderRadius: 8,
    cursor: "pointer",
  }),
);

export const LikeButton = styled(Button)(
  css({
    textTransform: "capitalize",
    borderRadius: "8px",
    minWidth: 120,
    padding: 3,
    marginRight: "5px",
    svg: {
      width: "20px",
      path: {
        stroke: "white",
      },
    },
  }),
);

export const CommentButton = styled(Button)(
  css({
    textTransform: "capitalize",
    borderRadius: "8px",
    minWidth: 120,
    padding: 3,
    marginLeft: "5px",
    svg: {
      width: "20px",
      path: {
        stroke: "white",
      },
    },
  }),
);

export const UsersLike = styled(Flex)(
  css({
    marginLeft: 2,
    marginRight: 1,
    img: {
      border: "3px solid",
      borderColor: "white",
      "&:not(:last-child)": {
        marginRight: "-10px",
      },
    },
  }),
);

export const Counter = styled("span")(
  css({
    color: "secondaryColor",
  }),
);

export const ContainerComments = styled(Flex)(
  css({
    bg: "paleGrey",
    flexDirection: "column",
    width: "100%",
    overflow:"hidden",
  }),
);

export const OverflowComments = styled("div")(
  css({
    position: "relative",
    height: "calc(100vh - 361px)",
    overflow: "auto",
    "@media (max-width:1024px)": {
      height: "calc(100vh - 500px)",
    },
  }),
);

export const CommentsHeader = styled("p")(
  css({
    padding: 16,
    fontSize: 18,
    color: "gray",
    marginBottom: 0,
    position: "relative",
    alignItems: "center",
  }),
);

export const Comment = styled(Flex)(
  css({
    padding: 3,
    borderRadius: 16,
    flex: "1 1 auto",
    flexDirection: "column",
    backgroundColor: "white",
  }),
  ({ highlight }) =>
    highlight &&
    css({
      border: `2px solid ${theme.colors.primaryColor}`,
    }),
);

export const Username = styled("h3")(
  css({
    fontSize: 2,
    marginBottom: 0,
    color: "backText",
    fontWeight: "600",
    lineHeight: "32px",
  }),
);

export const UserComment = styled("div")(
  css({
    my: 12,
    width: "100%",
  }),
);

export const CommentForm = styled("form")(
  css({
    position: "relative",
    backgroundColor: "white",
    borderRadius: "16px 16px 0 0",
    "@media (max-width: 539px)": {
      paddingBottom: "100px",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: "-70px",
      left: 0,
      width: "calc(100% - 12px)",
      height: "70px",
      background: "linear-gradient(to top, white, transparent)",
      "@media (max-width:1024px)": {
        display: "none",
      },
    },
  }),
);

export const ContainerInputArea = styled("div")(
  css({
    width: "100%",
  }),
);

export const CommentInput = styled("textarea")(
  css({
    width: "100%",
    height: "80px",
    border: "2px solid",
    borderColor: "textColor",
    resize: "none",
    padding: "4px 17px",
    color: "backText",
    marginBottom: "8px",
    "@media (max-width:1024px)": {
      padding: "20px 0px 0px 10px",
      height: "50px",
      border: "none",
    },
  }),
  ({ showCommentPublication }) =>
    showCommentPublication !== 0
      ? css({
          animation: "hideInputComment 0.5s ease-in",
          borderTop: "none",
          borderRadius: "0px 0px 8px 8px",
          "@keyframes hideInputComment": {
            from: {
              borderRadius: "8px",
            },
            to: {
              borderTop: "none",
              borderRadius: "0px 0px 8px 8px",
            },
          },
        })
      : css({
          animation: "showInputComment 0.5s ease-in",
          borderRadius: "8px",
          "@keyframes showInputComment": {
            from: {
              borderTop: "none",
              borderRadius: "0px 0px 8px 8px",
            },
            to: {
              borderRadius: "8px",
            },
          },
        }),
);

export const SendComment = styled("button")(
  css({
    width: 45,
    height: 40,
    border: "none",

    paddingLeft: "6px",
    borderRadius: "50%",
    backgroundColor: "paleGrey",
    textTransform: "capitalize",
    "@media (max-width:1024px)": {
      marginLeft: "7px",
      marginBottom: "10px",
    },
    "&:not(:disabled) svg path": {
      stroke: "secondaryColor",
      fill: "secondaryColor",
    },
  }),
  ({ isLoading }) =>
    isLoading &&
    css({
      borderRadius: "50%",
      border: "4px solid white",
      borderTop: `4px solid ${theme.colors.yellow}`,
      animation: "spin 2s linear infinite",
      "@keyframes spin": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(360deg)",
        },
      },
    }),
);

export const ContainerActionOptions = styled("button")(
  css({
    border: "none",
    display: "flex",
    transition: "1s",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    "&:hover": {
      opacity: 0.5,
    },
  }),
);

export const ContainerPopoveActions = styled("div")(
  css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }),
);

export const StyledPopover = styled(Popover)(
  css({
    borderRadius: 4,
    width: 300,
    border: "none",
    marginTop: 15,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  }),
);

export const ContainerOption = styled("div")(
  css({
    display: "flex",
    margin: "0px 16px",
    padding: "16px 0px",
    alignItems: "center",
    borderTop: "0.5px solid #D1D4E3",
  }),
);

export const TextOption = styled("h3")(
  css({
    margin: 0,
    fontSize: 2,
    color: "gray",
    fontFamily: "text",
    lineHeight: "20px",
  }),
);

export const OptionPopove = styled("button")(
  css({
    border: "none",
    display: "flex",
    transition: "1s",
    cursor: "pointer",
    alignItems: "center",
    backgroundColor: "transparent",
    "&:hover": {
      opacity: 0.5,
    },
  }),
);

export const ComponentExitComment = styled("header")(
  css({
    margin: 0,
    width: "100%",
    display: "flex",
    border: "2px solid",
    borderBottom: "none",
    alignItems: "flex-end",
    borderColor: "textColor",
    backgroundColor: "paleGrey",
    borderRadius: "8px 8px 0px 0px",
  }),
  ({ showCommentPublication }) =>
    showCommentPublication !== 0
      ? css({
          animation: "showCommentPublication 0.5s ease-in",
          opacity: 1,
          "@keyframes showCommentPublication": {
            from: {
              visibility: "hidden",
              opacity: 0,
            },
            to: {
              visibility: "visible",
              opacity: 1,
            },
          },
        })
      : css({
          animation: "hideCommentPublication 0.5s ease-in",
          visibility: "hidden",
          opacity: 0,
          "@keyframes hideCommentPublication": {
            from: {
              visibility: "visible",
              opacity: 1,
            },
            to: {
              visibility: "hidden",
              opacity: 0,
            },
          },
        }),
);

export const TimeAnswer = styled("h4")(
  css({
    m: 0,
    fontSize: 1,
    color: "grayDefaultColor",
  }),
);

export const ViewAnswers = styled("h5")(
  css({
    fontSize: 1,
    cursor: "pointer",
  }),
);

export const ContainerAnswer = styled("div")(
  css({
    overflow: "hidden",
  }),
  ({ height }) =>
    height &&
    css({
      height: `calc(${height}px - 20px)`,
    }),
);

export const TextExitComment = styled("h3")(
  css({
    margin: 0,
    width: "100%",
    fontSize: "14px",
    color: "textColor",
    padding: "0px 4px 4px 0px",
    borderBottom: "1px solid #D1D4E3",
  }),
);

export const ButtonCloseComment = styled("button")(
  css({
    border: "none",
    transition: "0.5s",
    padding: "2px 2px 2px 4px",
    backgroundColor: "transparent",
    borderBottom: "1px solid #D1D4E3",
    svg: {
      width: "20px",
      height: "20px",
    },
    "&:hover": {
      opacity: 0.5,
    },
  }),
);

export const ContainerAnswers = styled("div")(
  css({
    animation: "showAnswers 0.5s ease-in",
    opacity: 0,
    height: 0,
    visibility: "hidden",
    "@keyframes showAnswers": {
      from: {
        opacity: 1,
        height: "100%",
        visibility: "visible",
      },
      to: {
        height: 0,
        opacity: 0,
        visibility: "hidden",
      },
    },
    "&.uncollapsed": {
      animation: "hideAnswers 0.5s ease-in",
      opacity: 1,
      height: "100%",
      visibility: "visible",
      "@keyframes hideAnswers": {
        from: {
          opacity: 0,
          height: "0%",
          visibility: "hidden",
        },
        to: {
          opacity: 1,
          height: "100%",
          visibility: "visible",
        },
      },
    },
  }),
);

export const ComponentUsersMarked = styled("ul")(
  css({
    margin: 0,
    width: "100%",
    maxHeight: "0px",
    overflow: "auto",
    listStyleType: "none",
  }),
  ({ userMarked }) =>
    userMarked
      ? css({
          maxHeight: "100px",
          border: "2px solid",
          borderRadius: "8px",
          visibility: "visible",
          borderColor: "textColor",
        })
      : css({
          border: "none",
          visibility: "hidden",
        }),
);

export const ContainerUser = styled("li")(
  css({
    padding: "5px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "secondaryColorLight",
    },
  }),
);

export const NameUser = styled("li")(
  css({
    fontSize: "14px",
    marginLeft: "5px",
    color: "textColor",
  }),
);

export const MarkUser = styled("mark")(
  css({
    fontSize: 2,
    color: "backText",
    lineHeight: "20px",
    backgroundColor: "transparent",
    display: "inline-block",
  }),
  ({ markedUser }) =>
    markedUser &&
    css({
      color: "primaryColor",
      cursor: "pointer"
    }),
);

export const ContainerPublicationsSideBar = styled("div")(
  css({
    width: "100%",
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
  }),
);

export const CardPublication = styled("div")(
  css({
    width: "100%",
    height: "13.5rem",
    display: "flex",
    padding: "16px",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "flex-start",
    "&:hover": {
      opacity: 0.8,
    },
  }),
);

export const TittlePublication = styled("h3")(
  css({
    marginBottom: 0,
    fontSize: "20px",
    color: "backText",
    fontWeight: "600",
    paddingBottom: "16px",
  }),
);

export const ContentImage = styled("div")(
  css({
    width: "100%",
    maxHeight: "100%",
    display: "flex",
    overflow: "hidden",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
  }),
);

export const ImagePublication = styled("img")(
  css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  }),
);
