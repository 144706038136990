/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useCallback, useEffect } from "react";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AuthActions } from "store/ducks/auth";
import { professionalData } from "utils/constants/signUpList";
import { validateEmail } from "helpers/sharedHelpers";

import Button from "components/core/Button/Button";
import {
  Input,
  InputMask,
  Select,
  SelectMultiple,
} from "components/core/Form/Form";

import * as S from "./SignUpStyle";

const SignUp = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [errorPass, setErrorPass] = useState();
  const {
    isLoading,
    listTimeExperience,
    listTypesWork,
    listUserInterests,
    listUserTrainings,
    listState,
    listCities,
  } = useSelector((state) => state.auth);
  const [listCitiesState, setListCitiesState] = useState(listCities);
  const [showCities, setShowCities] = useState(false);
  const [formCheckValues, setFormCheckValues] = useState({
    pageSelect: 0,
    checkFormList: [false, false, false, false],
  });

  const validateUsername = /^[a-zA-Z0-9]+(?:[.\w-]*[a-zA-Z0-9]+)*$/;

  const [form, setForm] = useState({
    name: "",
    login: "",
    phone: "",
    email: "",
    datebirthday: "",
    state_id: "",
    city_id: "",
    cityName: "",
    password: "",
    training: "",
    trainings_ids: [],
    confirmPassword: "",
    experience_time_id: "",
    user_interests_ids: [],
    type_of_works_ids: [],
    work_regime: "",
  });

  const dispatchExperienceTimes = useCallback(
    () => dispatch(AuthActions.getExperienceTimes()),
    [dispatch],
  );

  const dispatchGetTypeOfWorks = useCallback(
    () => dispatch(AuthActions.getTypeOfWorks()),
    [dispatch],
  );

  const dispatchGetUserInterests = useCallback(
    () => dispatch(AuthActions.getUserInterests()),
    [dispatch],
  );

  const dispatchGetUserTrainings = useCallback(
    () => dispatch(AuthActions.getUserTrainings()),
    [dispatch],
  );

  const dispatchGetStateList = useCallback(
    () => dispatch(AuthActions.getStateList()),
    [dispatch],
  );

  const dispatchGetCitiesList = useCallback(
    (CitiesId) => dispatch(AuthActions.getCitiesList({ id: CitiesId })),
    [dispatch],
  );

  useEffect(() => {
    dispatchExperienceTimes();
    dispatchGetTypeOfWorks();
    dispatchGetUserInterests();
    dispatchGetUserTrainings();
    dispatchGetStateList();
    localStorage.clear();
  }, [
    dispatchExperienceTimes,
    dispatchGetTypeOfWorks,
    dispatchGetUserInterests,
    dispatchGetUserTrainings,
    dispatchGetStateList,
  ]);

  useEffect(() => {
    setListCitiesState(listCities);
  }, [listCities]);

  const formCheckValidate = (values, formCheck, valueType) => {
    if (!values && formCheck) {
      return intl.messages["general.buttons.inputRequired"];
    }
    if (valueType && formCheck) {
      if (valueType === "email" && !validateEmail(form.email)) {
        return intl.messages["general.buttons.inputEmailInvalid"];
      }
      if (valueType === "phone" && form.phone.length !== 16) {
        return intl.messages["general.buttons.inputPhoneInvalid"];
      }
      if (valueType === "login" && !validateUsername.test(form.login)) {
        return intl.messages["general.buttons.loginInvalid"];
      }
    }

    return false;
  };

  const handleSendCities = (valueId) => {
    dispatchGetCitiesList(valueId);
    setForm({ ...form, state_id: valueId, cityName: "", city_id: "" });
    setShowCities(false);
  };

  const handleSearchCities = (nameCity) => {
    if (nameCity.length > 0) {
      const filterCities = listCities?.filter(
        (cities) =>
          cities.text?.toLowerCase().indexOf(nameCity.toLowerCase().trim()) >
            -1 && cities,
      );
      setShowCities(true);
      setListCitiesState(filterCities);
      if (
        filterCities.length === 1 &&
        filterCities[0].text === nameCity.trim()
      ) {
        setForm({ ...form, city_id: filterCities[0].id });
        setShowCities(false);
      }
    }
    setForm({ ...form, cityName: nameCity });
  };

  const handleClick = (cities) => {
    setShowCities(false);
    setForm({
      ...form,
      cityName: cities.text,
      city_id: `${cities.id}`,
    });
  };

  const formCarrousel = useMemo(
    () => [
      <>
        <Input
          type="name"
          name="email"
          error={formCheckValidate(form.name, formCheckValues.checkFormList[0])}
          placeholder={intl.messages["profile.textPage.typeYourName"]}
          label={`${intl.messages["profile.name"]}*`}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          value={form.name}
        />
        <InputMask
          mask="(99) 9 9999-9999"
          maskChar={null}
          type="text"
          name="phone"
          value={form.phone}
          error={formCheckValidate(
            form.phone,
            formCheckValues.checkFormList[0],
            "phone",
          )}
          placeholder={intl.messages["profile.textPage.typePhone"]}
          label={`${intl.messages["profile.cellPhone"]}*`}
          onChange={(e) => setForm({ ...form, phone: e.target.value })}
        />
        <Input
          type="text"
          name="email"
          error={
            form.email &&
            !validateEmail(form.email) &&
            formCheckValues.checkFormList[0] &&
            intl.messages["general.buttons.inputEmailInvalid"]
          }
          placeholder={intl.messages["profile.textPage.typeYourEmail"]}
          label={intl.messages["general.email"]}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          value={form.email}
        />
      </>,
      <>
        <InputMask
          mask="99/99/9999"
          maskChar={null}
          type="text"
          name="datebirthday"
          value={form.datebirthday}
          placeholder={intl.messages["profile.textPage.typeYourBirthday"]}
          label={intl.messages["profile.datebirthday"]}
          onChange={(e) => {
            setForm({ ...form, datebirthday: e.target.value });
          }}
        />
        <Select
          value={form.state_id}
          error={formCheckValidate(
            form.state_id,
            formCheckValues.checkFormList[1],
          )}
          options={listState}
          label={`${intl.messages["profile.state"]}*`}
          onChange={(e) => handleSendCities(e.target.value)}
        />
        <S.ContentSelectCity>
          <S.ListCities cityValidation={showCities && form.cityName}>
            {showCities &&
              listCitiesState.map((cities) => (
                <S.ContentCities
                  key={cities.id}
                  onClick={() => handleClick(cities)}
                >
                  {cities.text}
                </S.ContentCities>
              ))}
          </S.ListCities>
          <Input
            type="text"
            name="city"
            error={formCheckValidate(
              form.cityName,
              formCheckValues.checkFormList[1],
            )}
            placeholder={intl.messages["login.textPage.typeYourCity"]}
            label={`${intl.messages["profile.city"]}*`}
            onChange={(e) => handleSearchCities(e.target.value)}
            value={form.cityName}
          />
        </S.ContentSelectCity>
        <Select
          value={form.experience_time_id}
          error={formCheckValidate(
            form.experience_time_id,
            formCheckValues.checkFormList[1],
          )}
          options={listTimeExperience}
          label={`${intl.messages["profile.timeExperience"]}*`}
          onChange={(e) =>
            setForm({ ...form, experience_time_id: e.target.value })
          }
        />
      </>,
      <>
        <SelectMultiple
          error={formCheckValidate(
            form.type_of_works_ids.length > 0,
            formCheckValues.checkFormList[2],
          )}
          options={listTypesWork}
          label={`${intl.messages["profile.suvinilProfessional.typeWork"]}*`}
          onChange={(e) => setForm({ ...form, type_of_works_ids: e })}
        />
        <Select
          value={form.work_regime}
          error={formCheckValidate(
            form.work_regime,
            formCheckValues.checkFormList[2],
          )}
          options={professionalData.workRegime}
          label={`${intl.messages["profile.suvinilProfessional.workRegime"]}*`}
          onChange={(e) => setForm({ ...form, work_regime: e.target.value })}
        />
        <Select
          value={form.training}
          error={formCheckValidate(
            form.training,
            formCheckValues.checkFormList[2],
          )}
          options={[
            {
              id: 0,
              text: "Sim",
              value: "Sim",
            },
            {
              id: 1,
              text: "Não",
              value: "Não",
            },
          ]}
          label={`${intl.messages["profile.suvinilProfessional.training"]}*`}
          onChange={(e) =>
            setForm({ ...form, training: e.target.value, trainings_ids: [] })
          }
        />
        {form.training === "Sim" && (
          <SelectMultiple
            options={listUserTrainings}
            error={formCheckValidate(
              form.trainings_ids,
              formCheckValues.checkFormList[2],
            )}
            label={`${intl.messages["profile.suvinilProfessional.whatTrainings"]}*`}
            onChange={(e) => setForm({ ...form, trainings_ids: e })}
          />
        )}
        <SelectMultiple
          options={listUserInterests}
          error={formCheckValidate(
            form.user_interests_ids.length > 0,
            formCheckValues.checkFormList[2],
          )}
          label={`${intl.messages["profile.suvinilProfessional.interests"]}*`}
          onChange={(e) => setForm({ ...form, user_interests_ids: e })}
        />
      </>,
      <>
        <Input
          type="text"
          name="login"
          error={formCheckValidate(
            form.login,
            formCheckValues.checkFormList[3],
            "login",
          )}
          placeholder={intl.messages["login.textPage.createYourUser"]}
          label={`${intl.messages["general.user"]}*`}
          onChange={(e) => setForm({ ...form, login: e.target.value })}
          value={form.login}
        />
        <Input
          type="password"
          name="newPassword"
          error={formCheckValidate(
            form.password,
            formCheckValues.checkFormList[3],
          )}
          placeholder={intl.messages["settings.textPage.createYourNewPassword"]}
          label={`${intl.messages["general.password"]}*`}
          onChange={(e) => setForm({ ...form, password: e.target.value })}
        />
        <Input
          type="password"
          name="confirmPassword"
          error={
            errorPass ||
            formCheckValidate(
              form.confirmPassword,
              formCheckValues.checkFormList[3],
            )
          }
          placeholder={
            intl.messages["settings.textPage.typeYourConfirmPassword"]
          }
          label={`${intl.messages["settings.confirmPassword"]}*`}
          onChange={(e) =>
            setForm({ ...form, confirmPassword: e.target.value })
          }
        />
      </>,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, formCheckValues],
  );

  const handleFormCheckValues = (page, newValuesCheck) => {
    setFormCheckValues({
      ...formCheckValues,
      pageSelect: page,
      checkFormList: newValuesCheck,
    });
  };

  const handleSignUp = (page, selectProgress) => {
    const valuesCheckForm = formCheckValues.checkFormList;

    if (page < formCheckValues.pageSelect) {
      setFormCheckValues({
        ...formCheckValues,
        pageSelect: page,
      });
      return;
    }

    switch (formCheckValues.pageSelect) {
      case 0:
        valuesCheckForm[0] = true;
        if (
          !form.name ||
          form.phone.length !== 16 ||
          (form.email && !validateEmail(form.email))
        ) {
          handleFormCheckValues(0, valuesCheckForm);
        } else {
          handleFormCheckValues(selectProgress ? page : 1, valuesCheckForm);
        }
        break;
      case 1:
        valuesCheckForm[1] = true;
        if (!form.state_id || !form.city_id || !form.experience_time_id) {
          handleFormCheckValues(1, valuesCheckForm);
        } else {
          handleFormCheckValues(selectProgress ? page : 2, valuesCheckForm);
        }
        break;
      case 2:
        valuesCheckForm[2] = true;
        if (
          form.type_of_works_ids.length === 0 ||
          !form.work_regime ||
          !form.training ||
          form.user_interests_ids.length === 0
        ) {
          handleFormCheckValues(2, valuesCheckForm);
        } else {
          handleFormCheckValues(selectProgress ? page : 3, valuesCheckForm);
        }
        break;
      case 3:
        valuesCheckForm[3] = true;
        if (
          !form.login ||
          !validateUsername.test(form.login) ||
          !form.password ||
          !form.confirmPassword
        ) {
          handleFormCheckValues(3, valuesCheckForm);
        } else {
          handleFormCheckValues(3, valuesCheckForm);
          if (form.password === form.confirmPassword) {
            dispatch(AuthActions.postCreateUserRequest(form));
          } else {
            setErrorPass(
              intl.messages["settings.textPage.passwordsDoNotMatch"],
            );
          }
        }
        break;
      default:
        valuesCheckForm[0] = false;
        handleFormCheckValues(3, valuesCheckForm);
    }
  };

  return (
    <S.ContainerFormSignUp>
      <S.ContainerRegister>
        {formCarrousel[formCheckValues.pageSelect]}
      </S.ContainerRegister>
      <S.ContainerButtonAndProgress>
        <Button
          type="button"
          isLoading={isLoading}
          variant={["gradient"]}
          name={
            formCheckValues.pageSelect === 3
              ? intl.messages["general.buttons.finish"]
              : intl.messages["general.buttons.continue"]
          }
          onClick={() => handleSignUp(formCheckValues.pageSelect)}
        />
        <S.MonitoringProgress>
          {formCarrousel.map((page, index) => (
            <S.EclipsePage
              key={index}
              progressForm={index <= formCheckValues.pageSelect}
              validateChecked={formCheckValues.checkFormList[index]}
              onClick={() =>
                formCheckValues.checkFormList[index] &&
                handleSignUp(index, true)
              }
            />
          ))}
        </S.MonitoringProgress>
      </S.ContainerButtonAndProgress>
    </S.ContainerFormSignUp>
  );
};

export default SignUp;
