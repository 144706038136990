/* eslint-disable react/prop-types */
import React from "react";
import { ReactComponent as NotFoundGenetic } from "assets/img/newHasNoResults.svg";

import * as S from "./NotFoundGenericStyle";

const NotFoundGeneric = ({ textPage }) => {
  return (
    <S.ContainerNotFilesFound>
      <NotFoundGenetic />
      <S.ContainerTitle>{textPage}</S.ContainerTitle>
    </S.ContainerNotFilesFound>
  );
};

export default NotFoundGeneric;
