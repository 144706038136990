import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { CALENDAR_EVENTS } from "utils/constants/endpoints";
import { startAndEndDateOfTheMonth } from "helpers/sharedHelpers";
import moment from "moment";
import { Types } from "../ducks/calendar";

function* getCalendarEvents({ payload }) {
  let dataStartAndEndDateOfTheMonth = {};
  const data = new Date();
  dataStartAndEndDateOfTheMonth = yield startAndEndDateOfTheMonth(data);
  try {
    const { events } = yield requestAPI({
      verb: GET,
      endPoint: `${CALENDAR_EVENTS}?dateini=${
        payload !== undefined && payload.firstDayMonth
          ? payload.firstDayMonth
          : dataStartAndEndDateOfTheMonth.firstDayMonth
      }&dateend=${
        payload !== undefined && payload.endDayMonth
          ? payload.endDayMonth
          : dataStartAndEndDateOfTheMonth.endDayMonth
      }`,
    });
    yield put({
      events,
      type: Types.GET_CALENDAR_EVENTS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_CALENDAR_EVENTS_FAIL,
      ...error.response.data,
    });
  }
}

function* getNextEvents() {
  const currentDay = moment().format("YYYY-MM-DD");
  const finalDay = moment().add("1", "year").format("YYYY-MM-DD");
  const replaceDate = (evt) =>
    evt.hour_event
      .replace("2000-01-01", evt.date_event)
      .replace(":00.000Z", "");

  try {
    const { events } = yield requestAPI({
      verb: GET,
      endPoint: `${CALENDAR_EVENTS}?dateini=${currentDay}&dateend=${finalDay}`,
    });

    const dateOrderedEvents = yield events?.sort((a, b) => {
      const current = moment(replaceDate(a));
      const next = moment(replaceDate(b));
      return current - next;
    });

    const filteredEvents = dateOrderedEvents.filter((event) => {
      const currentHour = moment().format("YYYY-MM-DDTHH:mm");
      return replaceDate(event) > currentHour;
    });

    const nextEvents = filteredEvents;

    yield put({
      nextEvents,
      type: Types.GET_NEXT_EVENTS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_NEXT_EVENTS_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.CALENDAR_EVENTS, getCalendarEvents);
  yield takeLatest(Types.GET_NEXT_EVENTS, getNextEvents);
}
