import axios from "axios";
import * as urlsApi from "utils/constants/endpoints";
import urls from "utils/constants/urls";
import { style } from "style";

const pushLogin = () => {
  localStorage.clear();
  window.location.assign("/");
};

export const API_V2 = axios.create({
  baseURL: urlsApi.basePathV2,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});

export const API_V3 = axios.create({
  baseURL: urlsApi.basePathV3,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});

const API = axios.create({
  baseURL: urlsApi.basePath,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});
API.interceptors.response.use(
  (response) => {
    if (!localStorage.getItem("access-token") && !urls.ROUTES.SIGNUP) {
      localStorage.setItem("access-token", response.headers["access-token"]);
      localStorage.setItem("uid", response.headers.uid);
      localStorage.setItem("client", response.headers.client);
      localStorage.setItem("resource-type", response.headers["resource-type"]);
      return response;
    }
    localStorage.getItem("access-token");
    localStorage.getItem("host-header");
    return response;
  },
  (error) =>
    error.response.status === 401 ? pushLogin() : Promise.reject(error),
);
export default API;
