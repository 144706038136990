import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";

import { Creators as StoreActions } from "store/ducks/store";

import { smartFormatterDate } from "helpers/sharedHelpers";
import { ReactComponent as IconCoins } from "assets/img/icons/iconCoins.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";

import { Overflow } from "components/presentation/Sidebar/SidebarStyle";
import { ImgProduct, NameProduct, ValuePrice } from "../StoreStyle";
import { ContainerStore, RequestDate, MoreInformations, DefaultMessage } from "./SidebarStoreStyle";

const SidebarStore = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rescue } = useSelector((state) => state.store);
  const requested = rescue.product_not_despatched;
  const despatched = rescue.product_despatched;

  const [overflowHeight, setOverflowHeight] = useState();
  const dispatchGetRescue = useCallback(
    () => dispatch(StoreActions.getRescue()),
    [dispatch],
  );

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });

  useEffect(() => {
    setOverflowHeight(document.body.getBoundingClientRect().height / 2 - 101);
  }, []);

  return (
    <Flex flex={1} flexDirection="column">
      {isDesktop && (
        <Flex id="sectionAwait" flex={3} flexDirection="column" width="100%">
          <PageTitle title={intl.messages["store.requestedProducts"]} fontSize={3} />
          <Overflow
            flexDirection="column"
            spaceChildren={3}
            height={`${overflowHeight}px`}
          >
            {requested?.length > 0 &&
              requested.map((product) => {
                if (product.product) {
                  const { image, name, valuescore, default_message_to_send } = product.product;
                  const { id } = product.purchasehistory;
                  const date = smartFormatterDate(
                    product.purchasehistory.created_at,
                    "L",
                  );

                  return (
                    <ContainerStore key={id}>
                      <ImgProduct src={image.url} />
                      <Flex width="100%" flexDirection="column">
                        <Flex justifyContent="space-between">
                          <NameProduct>{name}</NameProduct>
                        </Flex>
                        <Flex mb="2px" alignItems="center" spaceChildren={2}>
                          <IconCoins width={18} height={18} />
                          <ValuePrice sidebar>{valuescore}</ValuePrice>
                        </Flex>
                        <RequestDate>
                          {intl.messages["store.textPage.requested"]}: {date}
                        </RequestDate>

                        <MoreInformations
                          onClick={() =>
                            document
                              .querySelector(`#purchasehistory-${id}`)
                              .classList.toggle("uncollapsed")
                          }
                          key={`purchasehistory-${id}`}
                          id={`purchasehistory-${id}`}
                          collapse
                        >
                          <Flex>
                            <ArrowLeftIcon className="collapse-trigger"/>
                            <Flex width="100%" flexDirection="column">
                              {intl.messages["store.textPage.moreInformation"]}
                              <DefaultMessage className="collapse-element">
                                {default_message_to_send || intl.messages[
                                    "store.textPage.allRightContactHRToRedeemYourProduct"
                                ]}
                              </DefaultMessage>
                            </Flex>
                          </Flex>
                        </MoreInformations>
                      </Flex>
                    </ContainerStore>
                  );
                }
                return null;
              })}
          </Overflow>
        </Flex>
      )}

      <Flex id="sectionAwait" flex={3} flexDirection="column" width="100%">
        <PageTitle
          title={intl.messages["store.despatchedProducts"]}
          fontSize={3}
        />
        <Overflow
          flexDirection="column"
          spaceChildren={3}
          height={`${overflowHeight}px`}
        >
          {despatched?.length > 0 &&
            despatched.map((product) => {
              const { image, name, valuescore, default_message_to_send } = product.product;
              const { id } = product.purchasehistory;
              const date = smartFormatterDate(
                product.purchasehistory.updated_at,
                "L",
              );

              return (
                <ContainerStore spaceChildren={3} key={id}>
                  <ImgProduct src={image.url} sidebar />
                  <Flex width="100%" flexDirection="column">
                    <Flex justifyContent="space-between">
                      <NameProduct sidebar>{name}</NameProduct>
                    </Flex>
                    <Flex mb="2px" alignItems="center" spaceChildren={2}>
                      <IconCoins width={18} height={18} />
                      <ValuePrice sidebar>{valuescore}</ValuePrice>
                    </Flex>
                    <RequestDate>
                      {intl.messages["store.textPage.despatched"]}: {date}
                    </RequestDate>

                    <MoreInformations
                      onClick={() =>
                        document
                          .querySelector(`#purchasehistory-${id}`)
                          .classList.toggle("uncollapsed")
                      }
                      key={`purchasehistory-${id}`}
                      id={`purchasehistory-${id}`}
                      collapse
                    >
                      <Flex>
                        <ArrowLeftIcon className="collapse-trigger"/>
                        <Flex width="100%" flexDirection="column">
                          {intl.messages["store.textPage.moreInformation"]}
                          <DefaultMessage className="collapse-element">
                            {default_message_to_send || intl.messages[
                                "store.textPage.allRightContactHRToRedeemYourProduct"
                            ]}
                          </DefaultMessage>
                        </Flex>
                      </Flex>
                    </MoreInformations>
                  </Flex>
                </ContainerStore>
              );
            })}
        </Overflow>
      </Flex>
    </Flex>
  );
};

export default SidebarStore;
