/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { smartFormatterDate } from "helpers/sharedHelpers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";

import { Creators as PublicationActions } from "store/ducks/detailspublication";

import { ReactComponent as EditIcon } from "assets/img/icons/editIcon.svg";
import { ReactComponent as RemoveIcon } from "assets/img/icons/removeIcon.svg";
import { ReactComponent as ActionOptionsIcon } from "assets/img/icons/actionOptions.svg";
import { ReactComponent as IconClose } from "assets/img/icons/iconFechar.svg";
import { ReactComponent as SendIcon } from "assets/img/icons/send.svg";
import * as S from "../sidebarPublication/SidebarPublicationStyle";

const Comments = ({ canvas_comments, markUsersPublish, sendingComment }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user: currentUser } = useSelector(({ user }) => user);
  const commentedUser = canvas_comments?.find(
    ({ user }) => user?.id === currentUser?.id,
  );
  const [commentPublication, setCommentPublication] = useState("");
  const [idEditCommentPublication, setIdEditCommentPublication] = useState(0);
  const [idCommentAnswer, setIdCommentAnswer] = useState(0);
  const [showUserMarked, setShowUserMarked] = useState(false);
  const [nickname, setNickname] = useState("");

  const handleEditComment = (idComment, valueComment) => {
    setCommentPublication(valueComment);
    setIdEditCommentPublication(idComment);
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      handleCreateComment(event);
    }
  };

  const dispatchGetMarkUsers = useCallback(
    (userName) =>
      dispatch(PublicationActions.getMarkUsers({ id, userSearch: userName })),
    [dispatch, id],
  );

  const handleMarkUserComment = (value) => {
    setCommentPublication(value);
    const lastElem = document.getElementById("commentArea").selectionStart;
    if (value[lastElem - 1] === "@") {
      setShowUserMarked(true);
    } else if (value[lastElem - 1] !== " ") {
      const startElem = value.lastIndexOf("@", lastElem);
      const stringPresent = value.substring(startElem + 1, lastElem);
      if (stringPresent.length > 1) {
        dispatchGetMarkUsers(stringPresent);
      }
    } else {
      setShowUserMarked(false);
    }
  };

  const handleCreateComment = (evt) => {
    evt.preventDefault();
    if (commentPublication.trim().length > 0) {
      if (idEditCommentPublication !== 0) {
        dispatch(
          PublicationActions.patchCommentPublication({
            id,
            idEditCommentPublication,
            comment: commentPublication,
          }),
        );
      } else if (idCommentAnswer !== 0) {
        dispatch(
          PublicationActions.postCommentPublication({
            id,
            repliedId: idCommentAnswer,
            comment: commentPublication,
          }),
        );
      } else {
        dispatch(
          PublicationActions.postCommentPublication({
            id,
            comment: commentPublication,
          }),
        );
      }
    }
    setNickname("");
    setIdCommentAnswer(0);
    setCommentPublication("");
    setIdEditCommentPublication(0);
  };

  const handleRemoveComment = (idComment) => {
    dispatch(PublicationActions.deleteCommentPublication({ id, idComment }));
  };

  const toggleCollapse = (index) => {
    const target = document.getElementById(`comment-${index}`);
    target.classList.toggle("uncollapsed");
  };

  const handleSelectdUser = (nickName) => {
    const lastElem = document.getElementById("commentArea").selectionStart;
    const startElem = commentPublication.lastIndexOf("@", lastElem);
    const valueString = `${commentPublication.slice(
      0,
      startElem,
    )}@${nickName}${commentPublication.slice(lastElem)}`;
    setCommentPublication(valueString);
    setShowUserMarked(false);
    dispatchGetMarkUsers();
  };

  const handleCommentDate = (comment) => {
    const currentDate = smartFormatterDate(new Date(), "L").split("/");
    const commentData = smartFormatterDate(comment?.created_at, "L").split("/");

    if (currentDate[1] - commentData[1] >= 4) {
      return smartFormatterDate(comment?.created_at, "L");
    }
    if (currentDate[1] - commentData[1] >= 2) {
      return `Há ${currentDate[1] - commentData[1]} meses`;
    }
    if (currentDate[1] - commentData[1] === 1) {
      return `Há 1 mês`;
    }
    if (currentDate[1] - commentData[1] < 1) {
      if (currentDate[0] - commentData[0] >= 21) {
        return `Há 3 semanas`;
      }
      if (currentDate[0] - commentData[0] >= 14) {
        return `Há 2 semanas`;
      }
      if (currentDate[0] - commentData[0] >= 7) {
        return `Há 1 semana`;
      }
      if (currentDate[0] - commentData[0] < 1) {
        if (
          smartFormatterDate(new Date(), "HH") -
            smartFormatterDate(comment?.created_at, "HH") ===
          1
        ) {
          return `Há 1 hora `;
        }
        if (
          smartFormatterDate(new Date(), "HH") -
            smartFormatterDate(comment?.created_at, "HH") >=
          1
        ) {
          return `Há ${
            smartFormatterDate(new Date(), "HH") -
            smartFormatterDate(comment?.created_at, "HH")
          } horas `;
        }
        if (
          smartFormatterDate(new Date(), "mm") -
            smartFormatterDate(comment?.created_at, "mm") >=
          2
        ) {
          return `Há ${
            smartFormatterDate(new Date(), "mm") -
            smartFormatterDate(comment?.created_at, "mm")
          } minutos`;
        }
        return `Agora mesmo`;
      }
      if (currentDate[0] - commentData[0] === 1) {
        return `Há ${currentDate[0] - commentData[0]} dia`;
      }
      return `Há ${currentDate[0] - commentData[0]} dias`;
    }
    return `Error`;
  };

  const popoverEditComment = (valueComment, idComment) => (
    <S.StyledPopover>
      <S.ContainerPopoveActions>
        <PageTitle noBorder title="O que deseja fazer?" fontSize={2} />
        <S.ContainerOption>
          <S.OptionPopove
            type="button"
            onClick={() => handleEditComment(idComment, valueComment)}
          >
            <EditIcon />
            <S.TextOption>{intl.messages["general.buttons.edit"]}</S.TextOption>
          </S.OptionPopove>
        </S.ContainerOption>
        <S.ContainerOption>
          <S.OptionPopove
            type="button"
            onClick={() => handleRemoveComment(idComment)}
          >
            <RemoveIcon />
            <S.TextOption>
              {intl.messages["general.buttons.remove"]}
            </S.TextOption>
          </S.OptionPopove>
        </S.ContainerOption>
      </S.ContainerPopoveActions>
    </S.StyledPopover>
  );
  return (
    <S.ContainerComments>
      <S.CommentsHeader commented={commentedUser}>
        {canvas_comments.length}{" "}
        {intl.messages["publication.textPage.comments"]}
      </S.CommentsHeader>

      <S.OverflowComments>
        <Flex px={16} flexDirection="column" spaceChildren={4} pb={50}>
          {canvas_comments?.map(
            (comment) =>
              comment.replied_comment_id === null && (
                <S.Comment key={comment.id} spaceChildren={2}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex spaceChildren={2}>
                      <Avatar
                        url={comment.user?.image?.url}
                        width={32}
                        height={32}
                      />
                      <S.Username>{comment.user?.name}</S.Username>
                    </Flex>
                    {comment.user?.id === currentUser.id && (
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="bottom"
                        overlay={popoverEditComment(comment.body, comment.id)}
                      >
                        <S.ContainerActionOptions>
                          <ActionOptionsIcon />
                        </S.ContainerActionOptions>
                      </OverlayTrigger>
                    )}
                  </Flex>
                  <S.UserComment>
                    {comment.body.split(" ").map((palavra) =>
                      palavra[0] === "@" ? (
                        <S.MarkUser key={palavra.id} markedUser>
                          {palavra}
                        </S.MarkUser>
                      ) : (
                        <S.MarkUser key={palavra.id}>{palavra}</S.MarkUser>
                      ),
                    )}
                  </S.UserComment>
                  <Flex justifyContent="space-between">
                    <S.TimeAnswer>{handleCommentDate(comment)}</S.TimeAnswer>
                    <S.TimeAnswer>
                      <Flex spaceChildren={2}>
                        <S.ViewAnswers
                          onClick={() => {
                            setIdCommentAnswer(comment.id);
                            setNickname(comment.user.nickname);
                          }}
                        >
                          Responder
                        </S.ViewAnswers>
                        {comment.replies.length > 0 && (
                          <S.ViewAnswers
                            onClick={() => toggleCollapse(comment.id)}
                          >
                            {" "}
                            | Ver Respostas
                          </S.ViewAnswers>
                        )}
                      </Flex>
                    </S.TimeAnswer>
                  </Flex>
                  <S.ContainerAnswers id={`comment-${comment.id}`}>
                    {comment.replies.length > 0 &&
                      comment.replies.map((answer) => (
                        <Flex key={answer.id} ml={36} flexDirection="column">
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Flex spaceChildren={2}>
                              <Avatar
                                url={answer.user?.image?.url}
                                width={32}
                                height={32}
                              />
                              <S.Username>{answer.user?.name}</S.Username>
                            </Flex>
                            {answer.user?.id === currentUser.id && (
                              <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="bottom"
                                overlay={popoverEditComment(
                                  answer.body,
                                  answer.id,
                                )}
                              >
                                <S.ContainerActionOptions>
                                  <ActionOptionsIcon />
                                </S.ContainerActionOptions>
                              </OverlayTrigger>
                            )}
                          </Flex>
                          <S.UserComment id={`reply-${answer.id}`}>
                            {answer.body}
                          </S.UserComment>
                        </Flex>
                      ))}
                  </S.ContainerAnswers>
                </S.Comment>
              ),
          )}
        </Flex>
      </S.OverflowComments>

      <S.CommentForm onSubmit={handleCreateComment}>
        <Flex width={1} p={3} flexDirection="column">
          <S.ComponentExitComment
            showCommentPublication={idEditCommentPublication || idCommentAnswer}
          >
            <S.ButtonCloseComment
              onClick={() => {
                setNickname("");
                setIdCommentAnswer(0);
                setCommentPublication("");
                setIdEditCommentPublication(0);
              }}
              type="button"
            >
              <IconClose />
            </S.ButtonCloseComment>
            <S.TextExitComment>
              {idCommentAnswer !== 0 ? `Respodendo @${nickname}` : "Editando"}
            </S.TextExitComment>
          </S.ComponentExitComment>
          <Flex
            spaceChildren={1}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Avatar url={currentUser?.image?.url} width="28px" />
            <S.ContainerInputArea>
              <S.ComponentUsersMarked userMarked={showUserMarked}>
                {markUsersPublish.length > 0 &&
                  markUsersPublish.map((user) => (
                    <S.ContainerUser
                      key={user.id}
                      onClick={() => handleSelectdUser(user.nickname)}
                    >
                      <Avatar url={user?.image?.url} size={28} />
                      <S.NameUser>{user.name}</S.NameUser>
                    </S.ContainerUser>
                  ))}
              </S.ComponentUsersMarked>
              <S.CommentInput
                id="commentArea"
                value={commentPublication}
                onChange={(evt) => handleMarkUserComment(evt.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={
                  intl.messages[
                    "publication.textPage.commentInputResponsivePlaceholder"
                  ]
                }
                showCommentPublication={
                  idEditCommentPublication || idCommentAnswer
                }
              />
            </S.ContainerInputArea>
            <S.SendComment
              isLoading={sendingComment}
              disabled={commentPublication.trim().length === 0}
            >
              {!sendingComment && <SendIcon />}
            </S.SendComment>
          </Flex>
        </Flex>
      </S.CommentForm>
    </S.ContainerComments>
  );
};

export default Comments;
