import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getPostComments: ["payload"],
  getPostCommentsSuccess: ["payload", "isLoading"],
  getPostCommentsFailure: ["payload", "isLoading"],
});

const INITIAL_STATE = {
  comments: [], 
  hasMore:true,
  page: 1,
  pagination: {},
  error: null,
  isLoading: true,
};

const getPostComments = (state) => ({
  ...state,
});

const getPostCommentsSuccess = (state, { canvas_comments, pagination, hasMore, page }) => (
  {
  ...state,
  comments: canvas_comments,
  hasMore,
  page,
  pagination,
  error: null,
  isLoading: false,
});

const getPostCommentsFailure = (state, { error }) => ({
  ...state,
  comments: [],
  pagination: {},
  error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_POST_COMMENTS]: getPostComments,
  [Types.GET_POST_COMMENTS_SUCCESS]: getPostCommentsSuccess,
  [Types.GET_POST_COMMENTS_FAILURE]: getPostCommentsFailure,
});
