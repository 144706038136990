import styled from "styled-components";
import css from "@styled-system/css";
import { variant } from "styled-system";
import { Link } from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import { style } from "style";

import Flex from "components/core/Grid/Flex";

export const ContainerBirthdays = styled("div")(
  css({
    width: "100%",
    height: `calc(100vh - ${
      style.variables.child_profiles?.length > 0 ? "45px" : "10px"
    })`,
  }),
);

export const BirthdaysCount = styled("h2")(
  css({
    margin: 0,
    fontFamily: "heading",
    fontSize: "18px !important",
    lineHeight: "24px",
    color: "backText",
  }),
);

export const BirthdayToday = styled("span")(
  css({
    marginLeft: 16,
    fontFamily: "heading",
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: "24px",
    color: "#8C91A9",
  }),
);

export const BirthdayTodayBox = styled(Flex)`
  > div:first-child {
    @media (max-width: 670px) {
      flex-flow: column;

      div:nth-of-type(1),
      div:nth-of-type(1) h2 span {
        margin: 0;
      }
    }
  }
`;

export const BirthdayDate = styled(Flex)(
  css({
    flexGrow: 1,
    marginLeft: 24,
  }),
);

export const Calendar = styled(Link)(
  css({
    margin: 0,
    fontSize: 2,
    height: "33px",
    marginLeft: 18,
    color: "secondaryColor",
    fontFamily: "text",
    lineHeight: "20px",
    borderRadius: "8px",
    padding: "5px 18px",
    backgroundColor: "#F3F6FF",
    ":hover": {
      textDecoration: "none",
    },
  }),
);

export const ContainerHeader = styled(Flex)(
  css({
    padding: 3,
    width: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "grayDefaultColor",

    "@media (max-width: 411px)": {
      flexDirection: "row",
    },
  }),
);

export const TittleHeader = styled("h1")(
  css({
    margin: 0,
    padding: 0,
    fontSize: 4,
    fontWeight: "600",
    color: "backText",
    lineHeight: "32px",
    fontFamily: "heading",
    "@media (max-width: 411px)": {
      width: "100%",
    },
  }),
);

export const ContainerHeaderBirthday = styled(Flex)(
  css({
    display: "flex",
    alignItems: "center",
    marginRight: 2,
    position: "relative",
  }),
);

export const BirthdayContainer = styled(Flex)(
  css({
    padding: 4,
    overflow: "auto",
    flex: "0 0 auto",
    "@media (min-width: 767px)": {
      borderRight: "1px solid",
      borderColor: "grayDefaultColor",
      height: "100%",
    },
    "@media (max-width: 767px)": {
      marginBottom: "200px",
      width: "100%",
    },
  }),
  ({ birthdaysMonthShow }) =>
    !birthdaysMonthShow
      ? css({
          "@media (max-width: 766px)": {
            animation: "showBirthdaysMonth 1s ease-in",
            "@keyframes showBirthdaysMonth": {
              from: {
                width: "0%",
                visibility: "hidden",
              },
              to: {
                width: "100%",
                visibility: "visible",
              },
            },
          },
        })
      : css({
          "@media (max-width: 766px)": {
            animation: "hideBirthdaysMonth 1s ease-in",
            width: "0%",
            visibility: "hidden",
            "@keyframes hideBirthdaysMonth": {
              from: {
                width: "100%",
                visibility: "hidden",
              },
              to: {
                width: "0%",
                visibility: "hidden",
              },
            },
          },
        }),
);

export const MonthItem = styled("div")(
  css({
    bg: "paleGrey",
    display: "flex",
    padding: "12px",
    cursor: "pointer",
    minHeight: "68px",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "1s",
    ":hover, :focus": {
      opacity: "0.8",
    },
    svg: {
      marginTop: "4px",
      visibility: "visible",
    },
    "svg path": {
      stroke: "secondaryColor",
    },
    "@media (max-width: 320px)": {
      width: "280px",
    },
    "@media (min-width: 321px) and (max-width: 375px)": {
      width: "310px",
    },
    "@media (min-width: 376px)": {
      width: "342px",
    },
    "@media (min-width: 768px)": {
      svg: {
        visibility: "hidden",
      },
    },
  }),
  variant({
    variants: {
      selected: {
        bg: "secondaryColor",
        "h2, p": {
          color: "white",
        },
        "svg path": {
          stroke: "white",
        },
      },
    },
  }),
);

export const MonthName = styled("h2")(
  css({
    margin: 0,
    fontSize: 2,
    lineHeight: "24px",
    color: "backText",
    maxWidth: "190px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "heading",
  }),
);

export const CountMembers = styled("p")(
  css({
    margin: 0,
    fontSize: 1,
    lineHeight: "20px",
    color: "secondaryColor",
    fontFamily: "text",
  }),
);

export const MembersList = styled("div")(
  css({
    display: "flex",
    flex: "1 1 auto",
    flexWrap: "wrap",
    bg: "white",
    padding: "24px",
    zIndex: "1",
    alignContent: "flex-start",
    overflow: "auto",
    "@media (min-width: 410px) and (max-width: 766px)": {
      height: "731px",
    },
    "@media (min-width: 767px)": {
      svg: {
        visibility: "hidden",
        height: "calc(100vh - 137px)",
      },
    },
  }),
  ({ birthdaysMonthShow }) =>
    birthdaysMonthShow
      ? css({
          "@media (max-width: 767px)": {
            animation: "showBirthdays 1s ease-in",
            visibility: "visible",
            "@keyframes showBirthdays": {
              from: {
                visibility: "visible",
              },
              to: {
                visibility: "hidden",
              },
            },
          },
        })
      : css({
          "@media (max-width: 767px)": {
            animation: "hideBirthdays 1s ease-in",
            visibility: "hidden",
            "@keyframes hideBirthdays": {
              from: {
                visibility: "hidden",
              },
              to: {
                visibility: "visible",
              },
            },
          },
        }),
);

export const MemberItem = styled(Link)(
  css({
    display: "flex",
    flexDirection: "column",
    marginRight: 3,
    marginBottom: 4,
    ":hover": {
      textDecoration: "none",
    },
  }),
);

export const ContainerAvatar = styled("div")(
  css({
    width: "100px",
    height: "100px",
    "@media (min-width: 768px)": {
      width: "116px",
      height: "118px",
    },
  }),
);

export const MemberName = styled("h3")(
  css({
    margin: 0,
    fontSize: 2,
    paddingRight: 1,
    lineHeight: "25px",
    color: "backText",
    fontFamily: "text",
    textTransform: "capitalize",
    textOverflow: "ellipsis",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "@media (min-width: 768px)": {
      maxWidth: "116px",
      maxHeight: "118px",
    },
    maxWidth: "100px",
    maxHeight: "100px",
  }),
);

export const MemberBirthday = styled("span")(
  css({
    margin: 0,
    marginTop: "-3px",
    fontSize: 1,
    paddingRight: 1,
    lineHeight: "20px",
    color: "#888",
    fontFamily: "text",
    textOverflow: "ellipsis",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "@media (min-width: 768px)": {
      maxWidth: "116px",
      maxHeight: "118px",
    },
    maxWidth: "100px",
    maxHeight: "100px",
  }),
);

export const ContentBirthdayMembersList = styled("div")(
  css({
    height: "100%",
    display: "flex",
    "@media (max-width: 320px)": {
      width: "320px",
    },
    "@media (min-width: 321px) and (max-width: 376px)": {
      width: "375px",
    },
    "@media (min-width: 376px) and (max-width: 411px)": {
      width: "411px",
    },
  }),
);

export const BtnOutBirthday = styled("div")(
  css({
    svg: {
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
    ":hover, :focus": {
      "svg path": {
        stroke: "secondaryColor",
        opacity: "0.8",
      },
    },
    "@media (min-width: 768px)": {
      visibility: "hidden",
    },
  }),
  ({ birthdaysMonthShow }) =>
    birthdaysMonthShow
      ? css({
          "@media (max-width: 767px)": {
            animation: "showBtnBirthdays 2s ease-in",
            visibility: "visible",
            "@keyframes showBtnBirthdays": {
              from: {
                visibility: "visible",
              },
              to: {
                visibility: "hidden",
              },
            },
          },
        })
      : css({
          "@media (max-width: 767px)": {
            animation: "hideBtnBirthdays 1s ease-in",
            visibility: "hidden",
            "@keyframes hideBtnBirthdays": {
              from: {
                visibility: "hidden",
              },
              to: {
                visibility: "visible",
              },
            },
          },
        }),
);

export const BodyFilterBrand = styled("div")(
  css({
    display: "flex",
    marginRight: "24px",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  }),
);

export const ContainerFilterBrand = styled("div")(
  css({
    width: "40px",
    height: "40px",
    border: "none",
    display: "flex",
    transition: "1s",
    cursor: "pointer",
    marginLeft: "5px",
    alignItems: "center",
    borderRadius: "100px",
    justifyContent: "center",
    backgroundColor: "paleGrey",
    "&:hover": {
      opacity: 0.7,
    },
  }),
);

export const ContainerPopoverStyle = styled(Popover)(
  css({
    maxHeight:400,
    overflow:"scroll !important",
    width: 300,
    marginTop: 15,
    border: "none",
    borderRadius: 4,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  }),
);

export const BodyPopoveActions = styled("div")(
  css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }),
);

export const BodyPopoveAlternative = styled("div")(
  css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }),
);

export const ContainerUsersInner = styled("div")(
  css({
    display: "flex",
    flexWrap: "wrap",
  }),
);

export const SkeletonUserContainer = styled("div")(
  css({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
  })
)

export const SkeletonUserNameAndSectorContainer = styled("div")(
  css({
    display: "flex",
  })
)

export const SkeletonUserNameAndSectorDescription = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "10px"
  })
)

export const ContainerSearch = styled("div")(
  css({
    width: "100%",
    height: "10%",
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",
  })
)

export const ContainerMembersList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
