import React from "react";
import { FormattedMessage } from "react-intl";
import { takeLatest, put, select } from "redux-saga/effects";
import { GET, POST } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import {
  GET_ALL_PRODUCTS,
  BUY_PRODUCT,
  RESCUE,
} from "utils/constants/endpoints";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { Types } from "../ducks/store";

const getProductsActual = (state) => state.store.products;
const pageActual = (state) => state.store.page;

function* getProducts() {
  const page = yield select(pageActual);
  try {
    const products = yield requestAPI({
      verb: GET,
      endPoint: `${GET_ALL_PRODUCTS}?page=${page}&per_page=25`,
    });

    const actualList = page === 1 ? [] : yield select(getProductsActual);
    const newList = actualList.concat(products);
    const hasMore = products.length === 25;
    const nextpage = page + 1;

    yield put({
      products: newList,
      hasMore,
      page: nextpage,
      type: Types.GET_PRODUCTS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCTS_FAIL,
      ...error.response.data,
    });
  }
}

function* getDetailsProduct({ id }) {
  try {
    const product = yield requestAPI({
      verb: GET,
      endPoint: GET_ALL_PRODUCTS,
      id,
    });
    yield put({
      product,
      type: Types.GET_DETAILS_PRODUCT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_DETAILS_PRODUCT_FAIL,
      ...error.response.data,
    });
  }
}

function* buyProduct({ payload }) {
  try {
    yield requestAPI({
      verb: POST,
      endPoint: BUY_PRODUCT,
      data: payload,
    });
    yield put({
      type: Types.BUY_PRODUCT_SUCCESS,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
    };
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.BUY_PRODUCT_FAIL,
      ...error.response.data,
    });
  }
}

function* getRescue() {
  try {
    const rescue = yield requestAPI({
      verb: GET,
      endPoint: RESCUE,
    });
    yield put({
      rescue,
      type: Types.GET_RESCUE_SUCCESS,
    });
  } catch (error) {
    const errorMessage = error.response?.data || error;
    yield put({
      type: Types.GET_RESCUE_FAIL,
      errorMessage,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_PRODUCTS, getProducts);
  yield takeLatest(Types.GET_DETAILS_PRODUCT, getDetailsProduct);
  yield takeLatest(Types.BUY_PRODUCT, buyProduct);
  yield takeLatest(Types.GET_RESCUE, getRescue);
}
