import React from "react";
import { IntlProvider } from "react-intl";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import AlertModal from "components/core/AlertModal";
import { ThemeProvider } from "styled-components";
import { analytics } from "utils/constants/firebase";
import { store } from "./store/store";
import { Routes } from "./utils/routers";
import GlobalStyles from "./styles/global";
import theme from "./styles/theme";
import flattenMessages from "./helpers/flattenMessages";
import { CalendarProvider } from "./context/CalendarContexts";

import locale from "./locale";

analytics();

ReactDOM.render(
  <IntlProvider locale="pt-BR" messages={flattenMessages(locale["pt-BR"])}>
    <Provider store={store}>
      <CalendarProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Routes />
          <AlertModal />
        </ThemeProvider>
      </CalendarProvider>
    </Provider>
  </IntlProvider>,
  document.getElementById("root"),
);
