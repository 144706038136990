import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getCourses: [],
  getCoursesSuccess: ["payload", "isLoading"],
  getCoursesFail: ["payload", "isLoading"],

  getDetailsCourse: ["idCourse"],
  getDetailsCourseSuccess: ["payload", "isLoading"],
  getDetailsCourseFail: ["payload", "isLoading"],

  getMyCourses: [],
  getMyCoursesSuccess: ["payload", "isLoading"],
  getMyCoursesFail: ["payload", "isLoading"],

  resetCourse: ["payload"],
  resetCourseSuccess: ["payload", "isLoading"],
  resetCourseFail: ["payload", "isLoading"],

  postAnswer: ["payload"],
  postAnswerSuccess: ["payload", "isLoading"],
  postAnswerFail: ["payload", "isLoading"],

  finalizeSubmodule: ["payload"],
  finalizeSubmoduleSuccess: ["payload", "isLoading"],
  finalizeSubmoduleFail: ["payload", "isLoading"],

  finalizeModule: ["payload"],
  finalizeModuleSuccess: ["payload", "isLoading"],
  finalizeModuleFail: ["payload", "isLoading"],

  getCertificate: ["payload"],
  getCertificateSuccess: ["payload", "isLoadingModule"],
  getCertificateFail: ["payload", "isLoadingModule"],
});

const INITIAL_STATE = {
  courses: [],
  myCourses: [],
  coursesList: [],
  course_details: {},
  doneSubmodules: [],
  error: [],
  isLoading: true,
  isLoadingModule: false,
  isLoadingMyCourses: true,
  isLoadingFinalize: false,
  hasMore: true,
  page: 1,
};
const getCourses = (state) => ({
  ...state,
  isLoading: true,
});

const getCoursesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getCoursesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getDetailsCourse = (state) => ({
  ...state,
  isLoading: true,
});

const getDetailsCourseSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getDetailsCourseFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getMyCourses = (state) => ({
  ...state,
  isLoadingMyCourses: true,
});

const getMyCoursesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingMyCourses: false,
});

const getMyCoursesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const resetCourse = (state) => ({
  ...state,
});

const resetCourseSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const resetCourseFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const postAnswer = (state) => ({
  ...state,
});

const postAnswerSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const postAnswerFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const finalizeSubmodule = (state) => ({
  ...state,
  isLoadingFinalize: true,
});

const finalizeSubmoduleSuccess = (state, payload) => ({
  ...state,
  doneSubmodules: [...state.doneSubmodules, payload.idSubmodule],
  isLoadingFinalize: false,
});

const finalizeSubmoduleFail = (state, error) => ({
  ...state,
  isLoadingFinalize: false,
  ...error,
});

const finalizeModule = (state) => ({
  ...state,
  isLoadingModule: true,
  isLoadingFinalize: true,
});

const finalizeModuleSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingModule: false,
  isLoadingFinalize: false,
});

const finalizeModuleFail = (state, error) => ({
  ...state,
  isLoadingModule: false,
  isLoadingFinalize: false,
  ...error,
});

const getCertificate = (state) => ({
  ...state,
});

const getCertificateSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getCertificateFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_COURSES]: getCourses,
  [Types.GET_COURSES_SUCCESS]: getCoursesSuccess,
  [Types.GET_COURSES_FAIL]: getCoursesFail,

  [Types.GET_DETAILS_COURSE]: getDetailsCourse,
  [Types.GET_DETAILS_COURSE_SUCCESS]: getDetailsCourseSuccess,
  [Types.GET_DETAILS_COURSE_FAIL]: getDetailsCourseFail,

  [Types.GET_MY_COURSES]: getMyCourses,
  [Types.GET_MY_COURSES_SUCCESS]: getMyCoursesSuccess,
  [Types.GET_MY_COURSES_FAIL]: getMyCoursesFail,

  [Types.POST_ANSWER]: postAnswer,
  [Types.POST_ANSWER_SUCCESS]: postAnswerSuccess,
  [Types.POST_ANSWER_FAIL]: postAnswerFail,

  [Types.RESET_COURSE]: resetCourse,
  [Types.RESET_COURSE_SUCCESS]: resetCourseSuccess,
  [Types.RESET_COURSE_FAIL]: resetCourseFail,

  [Types.FINALIZE_SUBMODULE]: finalizeSubmodule,
  [Types.FINALIZE_SUBMODULE_SUCCESS]: finalizeSubmoduleSuccess,
  [Types.FINALIZE_SUBMODULE_FAIL]: finalizeSubmoduleFail,

  [Types.FINALIZE_MODULE]: finalizeModule,
  [Types.FINALIZE_MODULE_SUCCESS]: finalizeModuleSuccess,
  [Types.FINALIZE_MODULE_FAIL]: finalizeModuleFail,

  [Types.GET_CERTIFICATE]: getCertificate,
  [Types.GET_CERTIFICATE_SUCCESS]: getCertificateSuccess,
  [Types.GET_CERTIFICATE_FAIL]: getCertificateFail,
});
