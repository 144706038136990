import styled from "styled-components";
import css from "@styled-system/css";

export const ContainerGallery = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);

export const BodyGallery = styled("div")(
  css({
    width: "100%",
    height: "100%",
    padding: "12px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  }),
);

export const ContentFolderGallery = styled("div")(
  css({
    margin: "12px",
  }),
);

export const FolderGallery = styled("div")(
  css({
    width: "125px",
    display: "flex",
    height: "125px",
    transition: "1s",
    cursor: "pointer",
    alignItems: "center",
    marginBottom: "15px",
    borderRadius: "9.6px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "paleGrey",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    ":hover": {
      opacity: 0.7,
    },
  }),
);

export const imgCoverFolder = styled("img")(
  css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "9.6px",
  }),
);

export const NameFolderGallery = styled("div")(
  css({
    margin: "0 0 16px 0",
    fontSize: 2,
    height: "70px",
    width: "120px",
    fontFamily: "text",
    lineHeight: "24px",
    overflow: "hidden",
    textAlign: "center",
    color: "charcoalGrey",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }),
);
