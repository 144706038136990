import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as NotificationsActions } from "store/ducks/notifications";

import {
  smartFormatterDateNotifications,
  linkNotification,
} from "helpers/sharedHelpers";
import { useAnalytics } from "utils/hooks";
import {
  Notification,
  NotificationDate,
  NotificationTitle,
  NotificationDescription,
  NotificationContainer,
  NotificationCircle
} from "./NotificationsStyle";
import SkeletonNotifications, {SkeletonNotification} from "./SkeletonNotifications";

useAnalytics("screen_notification");

const Notifications = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  
  const [scrollParentRef, setScrollParentRef] = useState();

  const { notifications, isLoading, hasMore, page } = useSelector(
    (state) => state.notifications,
  );
  const dispatchGetNotifications = useCallback(
    () => dispatch(NotificationsActions.getNotifications({page:1})),
    [dispatch],
  );

  const fetchMoreData = () => {
    hasMore &&
      dispatch(
        NotificationsActions.getNotifications({
          page: page + 1
        }),
      );
  };

  useEffect(() => {
    dispatchGetNotifications();
  }, [dispatchGetNotifications]);

  return (
    <Portal
      setRef={setScrollParentRef}
      scrollRef={scrollParentRef}
    >
      <Flex flexDirection="column">
        <PageTitle title={intl.messages["menu.myNotifications"]} />
        <Flex flexDirection="column" marginBottom="50px">
          {isLoading ? (
            <SkeletonNotifications />
          ) : (
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={fetchMoreData}
              hasMore={hasMore}
              useWindow={false}
              threshold={50}
              getScrollParent={() => scrollParentRef}
              loader={<SkeletonNotification></SkeletonNotification>}
            >
              {notifications.length > 0 &&
              notifications.map((notification) => {
                const {
                  id,
                  kind,
                  idelement,
                  title,
                  created_at,
                  description,
                  canvas_publication_id,
                  notified
                } = notification;
                return (
                  <Notification key={id}>
                    <Link
                      to={linkNotification({
                        kind,
                        idelement,
                        title,
                        canvas_publication_id,
                      })}
                    >
                      <NotificationContainer>
                        <NotificationTitle>{title}</NotificationTitle>
                        {!notified && <NotificationCircle />}
                      </NotificationContainer>
                      <NotificationDescription
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                      <NotificationDate>
                        {smartFormatterDateNotifications(created_at, "LLL")}
                      </NotificationDate>
                    </Link>
                  </Notification>
                );
              })}
            </InfiniteScroll>
          )}
        </Flex>
      </Flex>
    </Portal>
  );
};

export default Notifications;
